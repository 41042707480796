<div class="signup-wrapper">
  <app-header class="topnavbar-wrapper" [onlyLogo]="true"></app-header>
  <h1 class="signup-title">
    <span *ngIf="!token">{{ "labels.signup_header" | translate }} </span>
    <span *ngIf="token">
      {{ "labels.invited_user_signup" | translate }} {{ workspaceName }} By
      {{ userName }}
    </span>
  </h1>
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="container signup-body">
        <div *ngIf="!!signupForm" class="justify-content-center signup-form-wrapper">
          <form
            class="form-main"
            [formGroup]="signupForm"
            (submit)="submitForm($event)"
          >
            <div class="form-group pb-1">
              <label class="label-required" for="name">{{
                "labels.name" | translate
              }}</label>
              <span class="text-danger pl-1">*</span>
              <div class="input-group with-focus">
                <input
                  type="text"
                  id="Name"
                  formControlName="name"
                  placeholder="Name"
                  class="form-control"
                  autocomplete="off"
                />
                <div class="input-group-append border-left">
                  <span class="input-group-text text-muted bg-white">
                    <em class="fas fa-user-tie label-icons"></em>
                  </span>
                </div>
              </div>
              <span
                class="text-danger"
                *ngIf="
                  signupForm.get('name')?.hasError('required') &&
                  !signupForm.get('name')?.valid &&
                  signupForm.get('name')?.touched
                "
              >
                {{ "errors.valid_name" | translate }}
              </span>
            </div>
            <div class="form-group pb-1">
              <label class="label-required">{{
                "labels.business_name" | translate
              }}</label>
              <div class="input-group">
                <input
                  type="text"
                  id="Business Name"
                  formControlName="business_name"
                  placeholder="Business Name"
                  class="form-control"
                  autocomplete="off"
                />
                <div class="input-group-append border-left">
                  <span class="input-group-text text-muted bg-white">
                    <em class="fa fa-building label-icons"></em>
                  </span>
                </div>
              </div>
            </div>

            <div class="form-group pb-1">
              <label class="label-required" for="email">{{
                "labels.email" | translate
              }}</label>
              <span class="text-danger pl-1">*</span>
              <div class="input-group">
                <input
                  type="email"
                  id="email"
                  formControlName="email"
                  placeholder="Email"
                  class="form-control"
                  autocomplete="off"
                />
                <div class="input-group-append border-left">
                  <span class="input-group-text text-muted bg-white">
                    <em class="fa fa-envelope label-icons"></em>
                  </span>
                </div>
              </div>
              <span
                class="text-danger"
                *ngIf="
                  signupForm.get('email')?.value === '' &&
                  signupForm.get('email')?.touched &&
                  !token
                "
              >
                {{ "errors.email_required" | translate }}
              </span>
              <span
                class="text-danger"
                *ngIf="
                  !signupForm.get('email')?.valid &&
                  signupForm.get('email')?.value !== '' &&
                  !token
                "
              >
                {{ "errors.valid_email" | translate }}
              </span>
            </div>
            <div class="form-group pb-1">
              <label class="label-required" for="email">{{
                "labels.phone" | translate
              }}</label>
              <div class="input-group">
                <input
                  type="number"
                  id="phone"
                  formControlName="phone"
                  placeholder="Phone"
                  class="form-control"
                  autocomplete="off"
                />
                <div class="input-group-append border-left">
                  <span class="input-group-text text-muted bg-white">
                    <em class="fa fa-phone label-icons"></em>
                  </span>
                </div>
              </div>
            </div>
            <ng-container *ngIf="!token">
              <div class="form-group pb-1">
                <label class="label-required">{{
                  "labels.how_large_your_company" | translate
                }}</label>
                <div class="row">
                  <div class="col-12 d-flex pr-0 company-type-list">
                    <ngx-select
                      [items]="companyTypes"
                      optionValueField="value"
                      optionTextField="label"
                      formControlName="business_employee_size"
                      placeholder="Select"
                    ></ngx-select>

                    <div class="input-group-append border-left">
                      <span
                        class="input-group-text text-muted bg-white select-dropdown-icon-border"
                      >
                        <em class="fas fa-list-alt label-icons"></em>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group pb-1">
                <label class="label-required">{{
                  "labels.category" | translate
                }}</label>
                <div class="row">
                  <div class="col-12 d-flex pr-0 company-type-list">
                    <ngx-select
                      [items]="categoryList"
                      optionValueField="value"
                      optionTextField="label"
                      formControlName="category"
                      placeholder="Select Category"
                    ></ngx-select>
                    <div class="input-group-append border-left">
                      <span
                        class="input-group-text text-muted bg-white select-dropdown-icon-border"
                      >
                        <em class="fas fa-users label-icons"></em>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group pb-1">
                <label class="label-required">{{
                  "labels.how_did_you_hear_about_us" | translate
                }}</label>
                <div class="input-group">
                  <input
                    type="text"
                    id="comment"
                    formControlName="comment"
                    placeholder="How Did You Hear About Us?"
                    class="form-control"
                    autocomplete="off"
                  />
                  <div class="input-group-append border-left">
                    <span class="input-group-text text-muted bg-white">
                      <em class="fas fa-comment-dots label-icons"></em>
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="form-group pb-1">
              <label class="label-required">{{
                "labels.password" | translate
              }}</label>
              <span class="text-danger pl-1">*</span>
              <div class="input-group">
                <input
                  type="password"
                  id="password"
                  formControlName="password"
                  placeholder="Password"
                  class="form-control"
                  autocomplete="off"
                />
                <div class="input-group-append border-left">
                  <span class="input-group-text text-muted bg-white">
                    <em class="fa fa-key label-icons"></em>
                  </span>
                </div>
              </div>
              <div
                *ngIf="
                  signupForm?.get('password')!.hasError('required') &&
                  (signupForm?.get('password')!.dirty ||
                    signupForm?.get('password')!.touched)
                "
                class="text-danger"
              >
                {{ "errors.required" | translate }}
              </div>
              <div
                *ngIf="
                  signupForm?.get('password')!.hasError('minlength') &&
                  (signupForm?.get('password')!.dirty ||
                    signupForm?.get('password')!.touched)
                "
                class="text-danger"
              >
                {{ "errors.valid_min_characters" | translate }}
              </div>
              <div
                *ngIf="
                  signupForm?.get('password')!.hasError('maxlength') &&
                  (signupForm?.get('password')!.dirty ||
                    signupForm?.get('password')!.touched)
                "
                class="text-danger"
              >
                {{ "errors.valid_max_characters" | translate }}
              </div>
            </div>
            <div class="form-group confirm-password">
              <label class="label-required">{{
                "labels.confirm_password" | translate
              }}</label>
              <span class="text-danger pl-1">*</span>
              <div class="input-group">
                <input
                  type="password"
                  id="confirm_password"
                  formControlName="confirm_password"
                  placeholder="Password Confirmation"
                  class="form-control"
                  autocomplete="off"
                />
                <div class="input-group-append border-left">
                  <span class="input-group-text text-muted bg-white">
                    <em class="fa fa-key label-icons"></em>
                  </span>
                </div>
              </div>
              <div
                *ngIf="
                  signupForm?.get('confirm_password')!.hasError('required') &&
                  (signupForm?.get('confirm_password')!.dirty ||
                    signupForm?.get('confirm_password')!.touched)
                "
                class="text-danger"
              >
                {{ "errors.required" | translate }}
              </div>
              <div
                *ngIf="signupForm.get('confirm_password')!.hasError('equalTo')"
                class="text-danger"
              >
                {{ "errors.passwords_do_not_match" | translate }}
              </div>
            </div>
            <div class="form-check align-items-center">
              <input
                class="form-check-input inputbox"
                type="checkbox"
                formControlName="agree_terms"
              />
              <label class="mb-0 term-condition ml-2">
                {{ "labels.accept_the" | translate }}
                <a href="#" class="terms-condition">{{
                  "labels.terms_and_conditions" | translate
                }}</a>
              </label>
            </div>
            <span
              *ngIf="
                signupForm.controls['agree_terms'].hasError('required') &&
                (signupForm.controls['agree_terms'].dirty ||
                  signupForm.controls['agree_terms'].touched)
              "
              class="text-danger"
            >
              {{ "errors.please_accept_terms" | translate }}
            </span>
            <div class="d-flex justify-content-end create-account-button">
              <button
                [disabled]=""
                class="btn primary-background d-flex align-items-center signup-btn"
              >
                <span>
                  <em class="far fa-check-circle check-icon text-white"></em>
                </span>
                <p class="mb-0 signup-btn-text text-white">
                  {{ "labels.create_account" | translate }}
                </p>
              </button>
            </div>
          </form>
          <div>
            <div class="d-flex justify-content-center already-account">
              <span class="pr-2">
                <i class="fas fa-user-tie text-black"></i>
              </span>
              <p class="text-black label-text">
                {{ "labels.already_have_an_account" | translate }}
              </p>
            </div>
            <a
              routerLink="/login"
              class="d-flex justify-content-center primary-color font-weight-bolder"
              ><span class="login-btn">{{
                "labels.login" | translate
              }}</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer-text">
  <span class="copyright"
  >&copy; {{ settings.getAppSetting("year") }} -
  {{ settings.getAppSetting("name") }}.</span
 >
 <a class="contact" target=”_blank” href="https://www.daybeam.com/contact/">Contact</a>
 <a class="privacy-policy" target=”_blank” href="https://www.daybeam.com/privacy-policy/">Privacy Policy</a>
 <a class="terms" target=”_blank” href="https://www.daybeam.com/terms/">Terms</a>
</div>
