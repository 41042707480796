import { ToasterService } from 'angular2-toaster';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/common/api.service';
import { LocalStorageService } from 'src/app/services/localstorage.service';
import { LoginService } from 'src/app/services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DaybeamValidator } from 'src/app/common/validation/daybeam-validator';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { SettingsService } from 'src/app/core/settings/settings.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  oError = {
    message: '',
  };
  isFromExtension: boolean = false;
  loginFormLoading: boolean = false;
  userEmailId: any;
  token: any;
  guid: any;

  constructor(
    private fb: FormBuilder,
    public _localStorageService: LocalStorageService,
    public _loginService: LoginService,
    public _toasterService: ToasterService,
    public _apiService: ApiService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _workspaceService: WorkspaceService,
    public settings: SettingsService
  ) {
    if (this._activatedRoute.snapshot.paramMap.get('guid')) {
			this.guid = this._activatedRoute.snapshot.paramMap.get('guid');
			this.loginViaGuid();
		}
  }

  ngOnInit(): void {
    let userEmail = this._localStorageService.getItem('email');
    this._activatedRoute.queryParams.subscribe((params) => {
      if (params?.from && params?.from == 'extension') {
        this.isFromExtension = true;
      }
      this.init();
    });
    if (this._localStorageService.getInviteToken()) {
      this.loginForm.controls.email.patchValue(userEmail);
      this.loginForm.controls.email.disable();
    }
  }

  init() {
    if (this._localStorageService.isUserLoggedIn()) {
      this.checkUserLoggedIn();
    } else {
      this._localStorageService.resetSessionStorage();
      this.loginForm = this.fb.group({
        email: ['', [Validators.required, DaybeamValidator.email]],
        password: ['', Validators.required],
      });
    }
  }

  loginViaGuid() {
		if (this._localStorageService.isUserLoggedIn()) {
			this._toasterService.pop(
				'error',
				"You're already logged in.",
				'Please refresh or open a new browser.'
			);
			return;
		}
		let payLoad = {
			guid: this.guid,
		};

		this._loginService
			.loginGuid(payLoad)
			.subscribe(
				(data) => {
          this._localStorageService.setAccessToken(data.access_token);
          this._localStorageService.setRefreshToken(data.refresh_token);
          this._localStorageService.setUserLoggedIn(true);
          if (this.isFromExtension) {
            this.postMessage();
          }

					this.getAuthUser();
				},
				(err) => {}
			);
	}

  checkUserLoggedIn() {
    if (this._localStorageService.isUserLoggedIn()) {
      const routerExtras = {};
      if (this.isFromExtension) {
        routerExtras['queryParams'] = {
          from: 'extension',
        };
        this._router.navigate(['/dashboard'], routerExtras);
      } else {
        this._router.navigate(['/dashboard']);
      }
    }
  }

  getAuthUser() {
    this._apiService.getAuthUser().subscribe(
      (data) => {
        this.loginFormLoading = false;
        this._loginService.currentUser.next(data.data)
        this._localStorageService.setCurrentUser(data.data);
        this._toasterService.pop('success', 'Welcome Back!');
        const params = {}
       params['include[]'] = 'role_id';
       this._workspaceService.getWorkspace(params).subscribe((res)=>{
        if(!res.data.length && !this.token){
       this._router.navigate(['/create-workspace']);
         } else {
           this._router.navigate(['/dashboard']);
         }
       })
        this._localStorageService.removeItem('email');
      },
      (err) => {}
    );
  }

  postMessage() {
    window.postMessage(
      {
        type: 'FROM_DAYBEAM_WEBPAGE',
        data: {
          accessToken: this._localStorageService.getAccessToken(),
          refreshToken: this._localStorageService.getRefreshToken(),
        },
      },
      '*'
    );
  }

  submitForm(event) {
    this.loginFormLoading = true;
    event.preventDefault();
    for (let c in this.loginForm.controls) {
      this.loginForm.controls[c].markAsTouched();
    }

    if (!this.loginForm.valid) {
      return;
    }
    this.loginForm.disable();
    var oAuthData = {
      grant_type: 'password',
      username: this.loginForm.value.email,
      client_id: environment.ApiClientId,
      client_secret: environment.ApiClientSecret,
    };

    Object.assign(this.loginForm.value, oAuthData);
    this._loginService.oAuthToken(this.loginForm.value).subscribe(
      (data) => {
        this.loginFormLoading = false;
        this._localStorageService.setAccessToken(data.access_token);
        this._localStorageService.setRefreshToken(data.refresh_token);
        this._localStorageService.setUserLoggedIn(true);
        if (this.isFromExtension) {
          this.postMessage();
        }

        this.getAuthUser();
      },
      (err) => {
        this.loginFormLoading = false;
        if ('error' in err.error) {
          if (err.error['error'] == 'invalid_credentials')
            err.error['message'] =
              'Sorry, that information wasn’t correct. ' +
              'Please check what you entered and try again.';
        }
        // this._toasterService.pop('error', err.error.message);
        this.loginForm.enable();
        this.oError = err.error;
      }
    );
  }
}
