import { Injectable } from '@angular/core';
import { CustomHttpService } from '../custom.http.service';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimeEntriesService {
  constructor(private _customHttpService: CustomHttpService) {}

  updateTimeEntry: Subject<any> = new Subject<boolean>();

  store(workspaceId, oData): Observable<any> {
    return this._customHttpService.post(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/time-entries',
      oData
    );
  }

  SearchWorkspaceTimeEntry(workspaceId: any, oParams?) {
    const params = new URLSearchParams();
    for (const key in oParams) {
      params.set(key, oParams[key]);
    }
    return this._customHttpService.get(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/time-entries/search' +
        (params.toString() ? '?' + params.toString() : '')
    );
  }

  stopTimerEntry(workspaceId: any, timeEntryId: any, oData: any) {
    return this._customHttpService.put(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/time-entries/' +
        timeEntryId +
        '/actions/stop-timer',
      oData
    );
  }

  runningTimerEntry(workspaceId) {
    return this._customHttpService.get(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/time-entries/running'
    );
  }
}
