<div class="item user-block" *ngIf="userBlockIsVisible()">
  <!-- User picture-->
  <div class="user-block-picture row">
    <div class="col-5 p-0">
      <div class="user-block-status profile-wrapper">
      <img
        class="img-thumbnail rounded-circle"
        [src]="profileImageUrl"
        onError="this.src='assets/img/user-placeholder.png';"
        alt="Avatar"
      />
      <div class="circle circle-lg"></div>
      </div>
    </div>
    <!-- Name and Job-->
    <div class="user-block-info text-left col-7">
      <span class="user-block-name">{{ "sidebar.welcome" | translate }}</span>
      <span
        title="{{ _localStorageService.getUserFullName() }}"
        class="user-block-role"
        >{{ _localStorageService.getUserFullName() }}</span
      >
    </div>
  </div>
</div>
