import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { CustomHttpService } from './custom.http.service';
import { API_CONSTANTS } from '../common/constant/ApiConstants';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  workspaceId: BehaviorSubject<any> = new BehaviorSubject(null);
  workspaceUpdated: Subject<any> = new Subject();
  workspaceArchived: Subject<any> = new Subject();
  seeWorkspace: Subject<any> = new Subject();
  startTimer: Subject<any> = new Subject();
  startedTimer: Subject<any> = new Subject();
  email: BehaviorSubject<any> = new BehaviorSubject({});
  constructor(private _customHttpService: CustomHttpService) {}

  postWorkspace(oData: object) {
    return this._customHttpService.post(
      API_CONSTANTS.API_ENDPOINT_URL + '/api/workspaces',
      oData
    );
  }

  getWorkspace(params?, include?) {
    const aparams = new URLSearchParams();

    for (const key in params) {
      aparams.set(key, params[key]);
    }
    for (const key in include) {
      aparams.append('include[]', include[key]);
    }
    return this._customHttpService.get(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces' +
        (aparams.toString() ? '?' + aparams.toString() : '')
    );
  }

  getWorkspaceWithArchived(params?) {
    const aparams = new URLSearchParams();

    for (const key in params) {
      aparams.set(key, params[key]);
    }

    return this._customHttpService.get(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces' +
        (aparams.toString() ? '?' + aparams.toString() : '')
    );
  }

  editWorkspace(oData: object, id) {
    return this._customHttpService.put(
      API_CONSTANTS.API_ENDPOINT_URL + '/api/workspaces/' + id,
      oData
    );
  }

  deleteWorkspace(id) {
    return this._customHttpService.delete(
      API_CONSTANTS.API_ENDPOINT_URL + '/api/workspaces/' + id
    );
  }

  getDashboardWorkspace(id) {
    return this._customHttpService.get(
      API_CONSTANTS.API_ENDPOINT_URL + '/api/workspaces/' + id + '/dashboard'
    );
  }

  archiveWorkspace(workspaceId: any, oData: any) {
    return this._customHttpService.put(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/actions/archive',
      oData
    );
  }

  UnarchiveWorkspace(workspaceId: any, oData: any) {
    return this._customHttpService.put(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/actions/unarchive',
      oData
    );
  }

  leaveWorkspace(workspaceId: any, oData: any) {
    return this._customHttpService.put(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/actions/leave',
      oData
    );
  }

  getWorkspaceAiAnswers(workspaceId: any, data) {
    return this._customHttpService.post(API_CONSTANTS.API_ENDPOINT_URL +'/api/workspaces/' + workspaceId + '/openai/chat/completions', data);
  }
}
