import { Injectable } from '@angular/core';
import { CustomHttpService } from './custom.http.service';
import { API_CONSTANTS } from '../common/constant/ApiConstants';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceInviteUserService {
  constructor(private _customHttpService: CustomHttpService) {}

  getTokenExits(token: any) {
    return this._customHttpService.get(
      API_CONSTANTS.API_ENDPOINT_URL + '/api/workspace-invite-users/' + token
    );
  }
}
