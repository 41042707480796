import { PricingModule } from './pricing/pricing.module';
import { Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';

import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { PasswordResetTokenComponent } from './pages/password-reset-token/password-reset-token.component';
import { WorkspaceInviteUsersComponent } from './workspace/workspace-invite-users/workspace-invite-users.component';
import { CreateWorkspaceComponent } from './pages/create-workspace/create-workspace.component';
import { AuthGuard } from '../common/guards/auth-guard.service';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
          data: {
            pageTitle: 'Dashboard'
          }
      },
      {
        path: 'groups',
        loadChildren: () =>
          import('./groups/groups.module').then((m) => m.GroupModule),
          data: {
            pageTitle: 'Groups'
          }
      },
      {
        path: 'tags',
        loadChildren: () =>
          import('./tags/tags.module').then((m) => m.TagsModule),
          data: {
            pageTitle: 'Tags'
          }
      },
      {
        path: 'tasks',
        loadChildren: () =>
          import('./task/task.module').then((m) => m.TaskModule),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'workspaces',
        loadChildren: () =>
          import('./workspace/workspace.module').then((m) => m.WorkspaceModule),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: 'pricing',
        loadChildren: () =>
          import('./pricing/pricing.module').then((m) => m.PricingModule),
      },
      {
        path: 'logout',
        loadChildren: () =>
          import('./logout/logout.module').then((m) => m.LogoutModule),
      },
    ],
  },

  // Not lazy-loaded routes
  { path: 'login', component: LoginComponent, data: {pageTitle: 'Login'} },
	{ path: 'login/:guid', component: LoginComponent, data: {pageTitle: 'Login'} },
  { path: 'signup', component: SignupComponent, data: {pageTitle: 'Signup'} },
  { path: 'signup/:token', component: SignupComponent, data: {pageTitle: 'Signup'} },
  { path: 'password/reset', component: ResetPasswordComponent, data: {pageTitle: 'Password/Reset'} },
  { path: 'password/reset/:token', component: PasswordResetTokenComponent, data: {pageTitle: 'Password/Reset'} },
  { path: 'invite-users/:token', component: WorkspaceInviteUsersComponent, data: {pageTitle: 'Invite-Users'} },
  {
    path: 'create-workspace',
    component: CreateWorkspaceComponent,
    canActivate: [AuthGuard],
  },

  // Not found
  { path: '**', redirectTo: 'dashboard' },
];
