export const environment = {
  production: false,
  development: true,
  env: 'staging',
  apiEndPoint: 'https://api.qa.daybeam.com/daybeam-api/public',
  baseURL: 'https://app.qa.daybeam.com/',
  ApiClientId: '2',
  ApiClientSecret: '7p7zaVzZLUbKw1P9Sd8eXcqMDHSCYSyE74cA0oGM',
  analyticObj: {
    isHotJarEnable: true
  }
};
