import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {
  
  private onDashboard = new BehaviorSubject<any>('');
  onDashboardData = this.onDashboard.asObservable();


  constructor() { }
  
  onDashboardNextData(data: any) {
    this.onDashboard.next(data);
  }
  
}
