import { DaybeamUtil } from 'src/app/common/DaybeamUtil';
import { Component, OnInit } from '@angular/core';
import { UserListService } from 'src/app/services/user-list.service';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { LocalStorageService } from 'src/app/services/localstorage.service';
import { UserService } from 'src/app/services/user/user.service';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-google-chrome-extension',
  templateUrl: './google-chrome-extension.component.html',
  styleUrls: ['./google-chrome-extension.component.scss']
})
export class GoogleChromeExtensionComponent implements OnInit {
  taskSuggestion = "Smart suggest";

  smartSuggestionList = DaybeamUtil.smartSuggestion()
  selectedoption: any;
  unsubscribe$ = new ReplaySubject<any>(10);
  workspaceId: any;
  extensionTaskSuggestion: any;
  suggestion: any;
  userRoleId: any;
  extensionTaskLogic: any;

  constructor(
    private _userService: UserService,
    private _workspaceService: WorkspaceService,
    private _localStorageService: LocalStorageService,
    private _toasterService: ToasterService
  ) { }

  ngOnInit(): void {
    this._workspaceService.workspaceId
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if(!!res){
          if (res?.id) this.workspaceId = res?.id;
          this.getProfileDetails();
        }
      })


  }

  onChange(e){
    this.extensionTaskSuggestion = e.label;
    this.userList();
  }

  userList(){
    const currentUser = this._localStorageService.getCurrentUser();
    const id = currentUser['id'];

   switch (this.extensionTaskSuggestion) {
    case 'OFF':
      this.suggestion = "OFF"
      break;

      case 'Auto-populate URL':
      this.suggestion = "AUTO_POPULATE_URL"
      break;

    default:
      this.suggestion = "SMART_SUGGEST"
      break;
   }

    const params = {
      extension_task_suggestion_logic : DaybeamUtil.extensionTaskSuggestion(this.suggestion),
    }
   this._userService.updateUserData(params,id).subscribe((res)=>{
    this.extensionTaskLogic = res.data?.extension_task_suggestion_logic;
    this._toasterService.pop('success', 'Google Chrome extension suggestion logic updated');
   })
  }

  getProfileDetails(){
    const currentUser = this._localStorageService.getCurrentUser();
    const id = currentUser['id'];
    this._userService.getProfileInformation(id).subscribe((res)=>{
      let selectedSuggestion = DaybeamUtil.extensionSelectedSuggestion(res.data?.extension_task_suggestion_logic);
      this.taskSuggestion = selectedSuggestion;
    })
  }

}
