import { Injectable } from '@angular/core';
import { CustomHttpService } from './custom.http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { API_CONSTANTS } from '../common/constant/ApiConstants';

@Injectable()
export class LoginService {
  currentUser: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private _customHttpService: CustomHttpService) {}

  oAuthToken(oData: string): Observable<any> {
    return this._customHttpService.post(API_CONSTANTS.OAUTH_TOKEN_URL, oData);
  }

  loginGuid(oData): Observable<any> {
  	return this._customHttpService.post(
  		API_CONSTANTS.OAUTH_TOKEN_URL + '/guid',
  		oData
  	);
  }
}
