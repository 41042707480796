import { environment } from './../environments/environment';
import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { ToasterConfig } from 'angular2-toaster';

import { SettingsService } from './core/settings/settings.service';
import Hotjar from '@hotjar/browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isShowHotjar: boolean = environment.analyticObj.isHotJarEnable;
  @HostBinding('class.layout-fixed') get isFixed() {
    return this.settings.getLayoutSetting('isFixed');
  }
  @HostBinding('class.aside-collapsed') get isCollapsed() {
    return this.settings.getLayoutSetting('isCollapsed');
  }
  @HostBinding('class.layout-boxed') get isBoxed() {
    return this.settings.getLayoutSetting('isBoxed');
  }
  @HostBinding('class.layout-fs') get useFullLayout() {
    return this.settings.getLayoutSetting('useFullLayout');
  }
  @HostBinding('class.hidden-footer') get hiddenFooter() {
    return this.settings.getLayoutSetting('hiddenFooter');
  }
  @HostBinding('class.layout-h') get horizontal() {
    return this.settings.getLayoutSetting('horizontal');
  }
  @HostBinding('class.aside-float') get isFloat() {
    return this.settings.getLayoutSetting('isFloat');
  }
  @HostBinding('class.offsidebar-open') get offsidebarOpen() {
    return this.settings.getLayoutSetting('offsidebarOpen');
  }
  @HostBinding('class.aside-toggled') get asideToggled() {
    return this.settings.getLayoutSetting('asideToggled');
  }
  @HostBinding('class.aside-collapsed-text') get isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  public _toasterConfig: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-bottom-right',
  });

  constructor(public settings: SettingsService) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => {
      let tableBody: any = document.getElementsByClassName('datatable-body')[0];
      let row: any = document.getElementsByClassName('datatable-body-row')[0];
      if (
        Number(
          tableBody?.style?.width?.substring(
            0,
            tableBody?.style?.width?.length - 2
          )
        ) >=
        Number(row?.style?.width?.substring(0, row?.style.width?.length - 2))
      ) {
      if(tableBody)  tableBody.style.overflow = 'hidden';
      } else {
      if(tableBody)  tableBody.style.overflow = 'auto';
      }
    }, 100);
  }

  ngOnInit() {
    // prevent empty links to reload the page
    document.addEventListener('click', (e) => {
      const target = e.target as HTMLElement;
      if (
        target.tagName === 'A' &&
        ['', '#'].indexOf(target.getAttribute('href') || '') > -1
      )
        e.preventDefault();
    });

    if (!!this.isShowHotjar) {
      if (environment.production) {
        const siteId = 3609250;
        const hotjarVersion = 6;
        Hotjar.init(siteId, hotjarVersion);
      }

      // if (environment.development) {
      //   const siteId = 3610283;
      //   const hotjarVersion = 6;
      //   Hotjar.init(siteId, hotjarVersion);
      // }
    }
  }
}
