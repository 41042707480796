<div class="row align-items-center">
  <div class="btn-group col-12 col-lg-6">
    <ng-container
      *ngFor="
        let tab of menuItems?.tabLists;
        let first = first;
        let last = last
      "
    >
      <button
        class="btn btn-lg btn-tab"
        [ngClass]="{
          'first-tab': first,
          'last-tab': last,
          'middle-tab': !first && !last,
          active: currentRoute !== tab?.routeUrl && currentRoute !== tab?.routeUrl2
        }"
        [routerLink]="tab.routeUrl"
      >
        {{ tab?.name }}
      </button>
    </ng-container>
  </div>
  <div class="col-12 col-lg-6 text-right add-new-task-btn">
    <button class="btn add-user p-0">
      <div class="d-flex align-items-center" (click)="advancedHeader()">
        <em class="fas fa-plus plus-icon text-white pl-3 py-2"></em>
        <span *ngIf="currentRoute === '/groups'" class="add-new-user-btn">
          {{ "labels.add_new_group" | translate }}
        </span>
        <span *ngIf="currentRoute === '/tags'" class="add-new-user-btn">
          {{ "labels.add_new_tag" | translate }}
        </span>
        <span *ngIf="currentRoute === '/tasks/approved' || currentRoute === '/tasks/waiting-for-approval'" class="add-new-user-btn">
          {{ "labels.add_new_task" | translate }}
        </span>
      </div>
    </button>
  </div>
</div>
