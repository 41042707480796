import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { SignupService } from '../../../services/signup.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { DaybeamValidator } from 'src/app/common/validation/daybeam-validator';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localstorage.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  valForm!: FormGroup;
  oError = {
    message: '',
  };
  serverStatusCode!: number;
  serverMessage!: string;
  resetPasswordLoading: boolean = false;

  constructor(
    public _settingsService: SettingsService,
    public _signupService: SignupService,
    public _formBuilder: FormBuilder,
    public _toasterService: ToasterService,
    private _router: Router,
    public _localStorageService: LocalStorageService
  ) {
    if (this._localStorageService.isUserLoggedIn()) {
      this.checkUserLoggedIn();
    } else {
      this.initForm();
    }
  }
  checkUserLoggedIn() {
    if (this._localStorageService.isUserLoggedIn()) {
      this._router.navigate(['/dashboard']);
    }
  }
  ngOnInit() {}

  initForm() {
    this.valForm = this._formBuilder.group({
      email: [
        '',
        Validators.compose([Validators.required, DaybeamValidator.email]),
      ],
    });
  }

  submitForm($ev) {
    this.resetPasswordLoading = true;
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }

    if (!this.valForm.valid) {
      return;
    }
    this.oError = {
      message: '',
    };
    this._signupService.resetPasswordUser(this.valForm.value).subscribe(
      (data) => {
        this.resetPasswordLoading = false;
        this.serverStatusCode = data.status;
        this.serverMessage = data.message;
        this.valForm.reset();
        let msg = data.message
          ? data.message
          : 'Check your email for password reset link.';
        this._toasterService.pop('success', msg);
      },
      (err) => {
        this.oError = err;
        this.resetPasswordLoading = false;
      }
    );
  }
}
