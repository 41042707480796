export const ResponseMessages = {
  'oauth.invalid_credentials':
    'Please check your username and password and try again.',
  'guid.not_exists':
    'User does not exist',
  'username.unique': 'Username already exists',
  'email.unique': 'Email already exists',
  'email.workspace_invite_email.exist': 'You are already a user of this account',
  'oauth.unauthorized_access': 'Sorry, something went wrong!',
  'auth.reset_password.link_expired': 'Password reset link has expired',
  'received_by_usernames.exists': 'Username not exists.',
  'email.exists': 'Email already exists',
  'name.workspace_name.exist': 'Workspace name already exist.',
  'name.workspace_tag_name.exist': 'Tag name already exist on this Workspace',
  'name.workspace_group_name.exist':
    'Group name already exist on this Workspace',
  'title.workspace_task_title.exist':
    'Task name already exist on this Workspace',
  'current_password.match_current_password': 'Passwords Does not match',
  'ends_at.not_runnable': "Timer already stopped",
};
