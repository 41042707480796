import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatorService } from '../core/translator/translator.service';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';

import { menu } from './menu';
import { routes } from './routes';
import { AuthGuard } from '../common/guards/auth-guard.service';
import { GoogleChromeExtensionComponent } from './google-chrome-extension/google-chrome-extension.component';

@NgModule({
  imports: [SharedModule, RouterModule.forRoot(routes), PagesModule],
  declarations: [
    GoogleChromeExtensionComponent
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class RoutesModule {
  constructor(public menuService: MenuService, tr: TranslatorService) {
    menuService.addMenu(menu);
  }
}
