import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { NavigationStart, Router } from '@angular/router';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  service_count = 0;

  constructor(private _loaderService: LoaderService,private router: Router) {}


  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.service_count++;
    this.router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
      }
    })
    return next.handle(request).pipe(
     tap({
      next: (event: HttpEvent<any>) =>{
        if(event instanceof HttpErrorResponse){}
      }, error: (err:any) =>{
        if(err instanceof HttpErrorResponse){
          if(err.status === 401){
          }
        }
      }
     }),
     finalize(() => {
      this.service_count--;
      if (this.service_count === 0) {
       this._loaderService.hide();
    }
    })
  )
  }
}
