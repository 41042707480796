import { Injectable, OnInit } from '@angular/core';
import { LocalStorageService } from './../localstorage.service';

@Injectable()
export class CommonService implements OnInit {
  constructor(public _localStorageService: LocalStorageService) {}

  ngOnInit() {}

  formatDateInRequiredFormat(date) {
    return this._localStorageService.formatDate(
      date,
      'MMMM DD, YYYY h:mm:ss A',
      true
    );
  }

  formatedDateInRequiredFormat(date) {
    return this._localStorageService.formatedDate(
      date,
      'MM/DD/YYYY h:mm A',
      true
    );
  }

  getTimeInHHMMSS(countDown) {
    const hours = countDown / 3600;
    const hour = Math.floor(hours);

    const remainingCountDown = countDown - (hour * 3600)
    const minute = Math.floor(remainingCountDown / 60);
    const second = Math.floor(remainingCountDown - (minute * 60));
    return (
      (hour > 9 ? hour : '0' + hour) +
      ':' +
      (minute > 9 ? minute : '0' + minute) +
      ':' +
      (second > 9 ? second : '0' + second)
    );
  }

  getTimeInHoursMinutes(countDown) {
    const hours = countDown / 3600;
    const hour = Math.floor(hours);
    const remainingCountDown = countDown - (hour * 3600)
    const minute = Math.floor(remainingCountDown / 60);
    const second = Math.floor(remainingCountDown - (minute * 60));
    let value;
    if (hour >= 1) {
      value = hour + ' hours, ' + (minute > 9 ? minute : '0' + minute) + ' minutes'
    } else if (hour < 1) {
      value = (minute > 9 ? minute : '0' + minute) + ' minutes, ' + (second > 9 ? second : '0' + second) + ' seconds'
    }
    return value;
  }

  getCalendarTime(countDown) {
    const hours = countDown / 3600;
    const hour = Math.floor(hours);

    const remainingCountDown = countDown - (hour * 3600)
    const minute = Math.floor(remainingCountDown / 60);
    const second = Math.floor(remainingCountDown - (minute * 60));
    let value = (hour > 9 ? hour : '0' + hour) +
    ':' +
    (minute > 9 ? minute : '0' + minute) +
    ':' +
    (second > 9 ? second : '0' + second)
  }

  getUpdateTimeInHHMMSS(countDown) {
    const hours = countDown / 3600;
    const hour = Math.floor(hours);

    const remainingCountDown = countDown - (hour * 3600)
    const minute = Math.floor(remainingCountDown / 60);
    const second = Math.floor(remainingCountDown - (minute * 60));
    const updatedHour = hour.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return (
      ( updatedHour ? updatedHour + ' hours, ' : '0' + updatedHour + ' hours, ') +
      (minute > 9 ? + minute + ' minutes, ' : '0' + minute + ' minutes, ') +
      (second > 9 ? ' and ' + second + ' seconds' : ' and ' + '0' + second + ' seconds')
    );
  }

  getUpdateTimeInHHMM(countDown) {
    const hours = countDown / 3600;
    const hour = Math.floor(hours);

    const remainingCountDown = countDown - (hour * 3600)
    const minute = Math.floor(remainingCountDown / 60);
    const second = Math.floor(remainingCountDown - (minute * 60));
    const updatedHour = hour.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return (
      (updatedHour ? updatedHour + ' hours, ' : '0' + updatedHour  + ' hours, ' ) +
      (second > 30 ? (minute >= 9 ? (minute + 1) + ' minutes' : '0' + (minute + 1) + ' minutes') : (minute > 9 ? minute + ' minutes' : '0' + minute + ' minutes '))
    );
  }

  getAmountInRequiredFormat(amount) {
    return Number(amount / 100).toFixed(2);
  }
}
