<div class="wrapper wrapper_bg_white">
  <!-- top navbar-->
  <app-header class="topnavbar-wrapper"></app-header>
  <!-- sidebar-->
  <app-sidebar class="aside-container"></app-sidebar>
  <!-- offsidebar-->
  <app-offsidebar class="offsidebar"></app-offsidebar>
  <!-- Main section-->
  <section class="section-container">
    <!-- Page content-->
    <div class="content-wrapper wrapper_bg_white">
      <router-outlet></router-outlet>
    </div>
  </section>
</div>
