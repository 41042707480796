import { DaybeamValidator } from './../../../common/validation/daybeam-validator';
import { SignupService } from './../../../services/signup.service';
import { ToasterService } from 'angular2-toaster';
import { LocalStorageService } from './../../../services/localstorage.service';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DaybeamUtil } from 'src/app/common/DaybeamUtil';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/common/api.service';
import { LoginService } from 'src/app/services/login.service';
import { ExternalScriptService } from 'src/app/services/common/external-script.service';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { SettingsService } from 'src/app/core/settings/settings.service';
// declare var grecaptcha: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  signupForm!: FormGroup;
  companyTypes = DaybeamUtil.companyTypes();
  categoryList = DaybeamUtil.categoryList();
  oError: object = {
    status: '',
  };
  workSpaceId: any;
  token: any;
  workspaceDetails: any;
  workspaceName: any;
  userName: any;
  signupFormLoading: boolean = false;
  userEmailId: any;

  constructor(
    private fb: FormBuilder,
    private _localStorageService: LocalStorageService,
    private _router: Router,
    private _toasterService: ToasterService,
    private _signupService: SignupService,
    public _loginService: LoginService,
    public _apiService: ApiService,
    public _externalScriptService: ExternalScriptService,
    private _activatedRoute: ActivatedRoute,
    private _workspaceService: WorkspaceService,
    public settings: SettingsService
  ) {
    if (this._activatedRoute.snapshot.paramMap.get('token')) {
      this.token = this._activatedRoute.snapshot.paramMap.get('token');
    }
  }

  ngOnInit(): void {
    let userEmail = this._localStorageService.getItem('userData')?.email;
    let userName = this._localStorageService.getItem('userData')?.name;
    this.workSpaceId =
      !!this._activatedRoute.snapshot.queryParamMap.get('workspaceId');
    this.init();
    this.workspaceDetails = this._localStorageService.getItem(
      'invite_token_details'
    );
    this.workspaceName = this.workspaceDetails?.workspace?.name;
    this.userName = this.workspaceDetails?.created_by_user?.name;

    if (this._localStorageService.getInviteToken()) {
      this.signupForm?.controls?.email?.patchValue(userEmail);
      this.signupForm?.controls?.name?.patchValue(userName);
      this.signupForm?.controls?.email?.disable();
    }
  }

  init() {
    if (this._localStorageService.isUserLoggedIn()) {
      this.checkUserLoggedIn();
    } else {
      // this.reloadGoogleRecaptchaScript();
      this.initForm();
    }
  }

  initForm() {
    this.signupForm = this.fb.group(
      {
        name: [
          '',
          Validators.compose([
            Validators.required,
          ]),
        ],
        business_name: [''],
        email: [
          '',
          Validators.compose([Validators.required, DaybeamValidator.email]),
        ],
        phone: [''],
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(16),
          ]),
        ],
        confirm_password: ['', Validators.required],
        agree_terms: [null, Validators.required],
        // recaptcha_token: [null],
      },
      {
        validator: DaybeamValidator.equalTo('password', 'confirm_password'),
      }
    );
    if (!this.workSpaceId) {
      this.signupForm.addControl('business_employee_size', new FormControl(''));
      this.signupForm.addControl('category', new FormControl(''));
      this.signupForm.addControl('comment', new FormControl(''));
    }
  }

  checkUserLoggedIn() {
    if (this._localStorageService.isUserLoggedIn()) {
      this._router.navigate(['/dashboard']);
    }
  }

  generateTokenGoogleRecaptcha() {
    const thisObject = this;
    // grecaptcha.ready(function () {
    //   grecaptcha
    //     .execute(environment.reCaptchaSiteKey, {
    //       action: 'appSignupPage',
    //     })
    //     .then(function (token) {
    //       thisObject.assignRecaptchaToken(token);
    //       thisObject.storeSubmit();
    //     });
    // });
  }
  assignRecaptchaToken(token) {
    this.signupForm.patchValue({ recaptcha_token: token });
  }
  // reloadGoogleRecaptchaScript() {
  //   this._externalScriptService
  //     .loadScript(
  //       'https://www.google.com/recaptcha/api.js?render=' +
  //         environment.reCaptchaSiteKey,
  //       'daybeam_recaptcha'
  //     )
  //     .then((data) => {})
  //     .catch((error) => console.log(error));
  // }
  storeSubmit() {
    if (!!this.token) {
      let payload = {
        name: this.signupForm.value.name,
        password: this.signupForm.value.password,
      };

      this._signupService.registerviaToken(payload, this.token).subscribe(
        (data) => {
          this.signupFormLoading = false;
          let msg = data.message
            ? data.message
            : 'Daybeam Sign Up Complete!';
          this._toasterService.pop('success', msg);
          this.autoLoginAfterSignup(
            this.signupForm.controls.email.value,
            this.signupForm.value.password
          );
          this.userName = this.signupForm.value.name;
          this._localStorageService.removeItem('email');
        },
        (err) => {
          this.oError = err;
          this.signupFormLoading = false;
        }
      );
    } else {
      this._signupService.register(this.signupForm.value).subscribe(
        (data) => {
          this.signupFormLoading = false;
          let msg = data.message
            ? data.message
            : 'Daybeam Sign Up Complete!';
          this._toasterService.pop('success', msg);
          this.autoLoginAfterSignup(
            this.signupForm.controls.email.value,
            this.signupForm.value.password
          );
          this.userName = this.signupForm.value.name;
        },
        (err) => {
          this.oError = err;
          this.signupFormLoading = false;
        }
      );
    }
  }

  autoLoginAfterSignup(email, password) {
    const auth_data = {
      grant_type: 'password',
      username: email,
      password: password,
      client_id: environment.ApiClientId,
      client_secret: environment.ApiClientSecret,
      client_signup: 1,
    };

    this._loginService.oAuthToken(JSON.stringify(auth_data)).subscribe(
      (data) => {
        this._localStorageService.setAccessToken(data.access_token);
        this._localStorageService.setUserLoggedIn(true);
        this.getAuthUser();
      },
      (err) => {
        this.oError = err;
      }
    );
  }

  getAuthUser() {
    this._apiService.getAuthUser().subscribe(
      (data) => {
        this._localStorageService.setCurrentUser(data.data);
        this._loginService.currentUser.next(data.data)
        this.redirectUser();
        // this._router.navigate(['/dashboard']);
      },
      (err) => {}
    );
  }

  redirectUser() {
    if (this._localStorageService.isUserLoggedIn()) {
      const params = {}
      params['include[]'] = 'role_id';
      this._workspaceService.getWorkspace(params).subscribe((res)=>{
       if(!res.data.length && !this.token){
      this._router.navigate(['/create-workspace']);
        } else {
          this._router.navigate(['/dashboard']);
        }
      })
    }
  }

  submitForm($ev: any) {
    this.signupFormLoading = true;
    $ev.preventDefault();
    for (const c in this.signupForm.controls) {
      this.signupForm.controls[c].markAsTouched();
    }

    if (!this.signupForm.valid) {
      return;
    }
    // this.generateTokenGoogleRecaptcha();
    this.storeSubmit();
  }
  removeVisibilityGoogleRecaptcha() {
    const elements = document.getElementsByClassName('grecaptcha-badge');
    while (elements.length > 0) {
      elements[0].parentNode
        ? elements[0].parentNode.removeChild(elements[0])
        : '';
    }
  }

  destroyGoogleRecaptcha() {
    this.removeVisibilityGoogleRecaptcha();
    this._externalScriptService.removeScript('daybeam_recaptcha');
  }

  ngOnDestroy() {
    this.destroyGoogleRecaptcha();
  }
}
