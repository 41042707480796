import { FormControl } from '@angular/forms';
import { LocalStorageService } from '../../../services/localstorage.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { WorkspaceService } from 'src/app/services/workspace.service';
@Component({
  selector: 'app-create-workspace',
  templateUrl: './create-workspace.component.html',
  styleUrls: ['./create-workspace.component.scss'],
})
export class CreateWorkspaceComponent implements OnInit {
  workSpaceName = new FormControl();
  CreateWorkspaceLoading = false;
  userName: any;
  constructor(
    private _router: Router,
    private _workspaceService: WorkspaceService,
    private _localStorageService: LocalStorageService,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    this.userName = this._localStorageService.getItem('current_user')?.name;
  }

  onWorkspaceCreate() {
    this.workSpaceName?.disable();
    if(this.CreateWorkspaceLoading) return;
    this.CreateWorkspaceLoading = true;
    let payload = {
      name: this.workSpaceName.value,
    };
    this._workspaceService.postWorkspace(payload).subscribe(
      (res) => {
        this.CreateWorkspaceLoading = false;
        const currentUser = this._localStorageService.getCurrentUser();
        const id = currentUser['id'];
        const param = {
          default_workspace_id: res?.data?.id,
        };

        let oCurrentUser = this._localStorageService.getCurrentUser();
        if(oCurrentUser['default_workspace_id'] == null){
            this._userService.updateUserData(param, id).subscribe((data) => {
              oCurrentUser['default_workspace_id'] = data.data.default_workspace_id;
                this._localStorageService.setCurrentUser(oCurrentUser);
              });
          }
        this._localStorageService.setWorkspace(res.data);
        this._localStorageService.setItem('role_Id', 2);
        this._router.navigate(['/dashboard']);
        this._workspaceService.workspaceId.next(res.data);
      },
      (error) => {
        this.CreateWorkspaceLoading = false;
        this.workSpaceName?.enable();
      }
    );
  }
}
