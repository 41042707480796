import { CropperSettings } from 'ngx-img-cropper';
import { environment } from 'src/environments/environment';

export class DaybeamUtil {
  static standardUser = 3;
  static PrimaryUser = 2;
  static WorkspaceAdmin = 4;
  static isFeedspaceInitialized = false;
  static jobDescriptionMaxLength = 5000;
  static companyTypes() {
    return [
      {
        label: '1-10 people',
        value: 'oneToTen',
      },
      {
        label: '10-30 people',
        value: 'tenToThirty',
      },
      {
        label: '30-60 people',
        value: 'thirtyToSixty',
      },
      {
        label: '60-100 people',
        value: 'sixtyToHundread',
      },
      {
        label: '100+ people',
        value: 'hundreadPlus',
      },
    ];
  }

  static categoryList() {
    return [
      {
        label: 'Accounting',
        value: 'Accounting',
      },
      {
        label: 'Administration & Office Support',
        value: 'Administration & Office Support',
      },
      {
        label: 'Advertising, Arts & Media',
        value: 'Advertising, Arts & Media',
      },
      {
        label: 'Banking & Financial Services',
        value: 'Banking & Financial Services',
      },
      {
        label: 'Call Centre & Customer Service',
        value: 'Call Centre & Customer Service',
      },
      {
        label: 'Community Services & Development',
        value: 'Community Services & Development',
      },
      {
        label: 'Construction',
        value: 'Construction',
      },
      {
        label: 'Consulting & Strategy',
        value: 'Consulting & Strategy',
      },
      {
        label: 'Design & Architechture',
        value: 'Design & Architechture',
      },
      {
        label: 'Education & Training',
        value: 'Education & Training',
      },
      {
        label: 'Engineering',
        value: 'Engineering',
      },
      {
        label: 'Farming, Animals & Conservation',
        value: 'Farming, Animals & Conservation',
      },
      {
        label: 'Government & Defence',
        value: 'Government & Defence',
      },
      {
        label: 'Healthcare & Medical',
        value: 'Healthcare & Medical',
      },
      {
        label: 'Hospitality & Tourism',
        value: 'Hospitality & Tourism',
      },
      {
        label: 'Human Resources & Recruitment',
        value: 'Human Resources & Recruitment',
      },
      {
        label: 'Information & Communication Technology',
        value: 'Information & Communication Technology',
      },
      {
        label: 'Insurance & Superannuation',
        value: 'Insurance & Superannuation',
      },
      {
        label: 'Legal',
        value: 'Legal',
      },
      {
        label: 'Manufacturing, Transport & Logistics',
        value: 'Manufacturing, Transport & Logistics',
      },
      {
        label: 'Marketing & Communications',
        value: 'Marketing & Communications',
      },
      {
        label: 'Mining, Resources & Energy',
        value: 'Mining, Resources & Energy',
      },
      {
        label: 'Real Estate & Property',
        value: 'Real Estate & Property',
      },
      {
        label: 'Retail & Consumer Products',
        value: 'Retail & Consumer Products',
      },
      {
        label: 'Sales',
        value: 'Sales',
      },
      {
        label: 'Science & Technology',
        value: 'Science & Technology',
      },
      {
        label: 'Sport & Recreation',
        value: 'Sport & Recreation',
      },
      {
        label: 'Trades & Services',
        value: 'Trades & Services',
      }
    ];
  }

  static smartSuggestion() {
    return [
        {label:'OFF'},
        {label:'Auto-populate URL'},
        {label:'Smart suggest'},
      ];
  }

  static extensionTaskSuggestion(Suggestion):any{

    switch (Suggestion) {
      case 'OFF':
        Suggestion = "OFF"
        break;

        case 'Auto-populate URL':
          Suggestion = "AUTO_POPULATE_URL"
          break;

          default:
            Suggestion = "SMART_SUGGEST"
            break;
          }
          return Suggestion;

        }

        static extensionSelectedSuggestion(Suggestion):any{

          switch (Suggestion) {
            case 'OFF':
              Suggestion = "OFF"
              break;

              case 'AUTO_POPULATE_URL':
                Suggestion = "Auto-populate URL"
                break;

                default:
                  Suggestion = "Smart suggest"
                  break;
                }
                return Suggestion;
              }

      static filterStatusList(options):any{
       let statusList:any = [];
       if(options?.has_billable?.count_billable > 0){
        statusList.push({ id: 3, is_billable: true, status: 'Billable Hours' });
       }
       if(options?.has_billable?.count_non_billable > 0){
        statusList.push({ id: 4, is_billable: false, status: 'Non-Billable Hours' });
       }
       if(options?.has_paid?.count_paid > 0){
        statusList.push({ id: 1, is_paid: true, status: 'Paid'});
       }
       if(options?.has_paid?.count_unpaid > 0){
        statusList.push({ id: 2, is_paid: false, status: 'Unpaid' });
       }
       return statusList;
      }

  static RoleList() {
    return [
      // {
      //   roleId: 1,
      //   role_name: 'Super Admin',
      // },
      // {
      //   roleId: 2,
      //   role_name: 'Primary User',
      // },
      {
        roleId: 3,
        role_name: 'Standard User',
      },
      {
        roleId: 4,
        role_name: 'Workspace Admin',
      },
    ];
  }

  static RoleData() {
    return {
      '2': 'Primary User',
      '3': 'Standard User',
      '4': 'Workspace Admin',
    };
  }
  static roleList() {
    return {
      '2': 'Primary User',
      3: 'Standard User',
      4: 'Workspace Admin',
    };
  }

  static RoleTypes() {
    return {
      standard_user: 3,
      workspace_Admin: 4,
    };
  }

  static getApiEndPointPath(): string {
    return environment.apiEndPoint;
  }
  static getApiEndPoint(): string {
    return this.getApiEndPointPath();
  }

  static getImageMimeTypes() {
    return [
      'image/png',
      'image/bmp',
      'image/cis-cod',
      'image/gif',
      'image/ief',
      'image/jpeg',
      'image/jpeg',
      'image/pipeg',
      'image/tiff',
    ];
  }
  static getImageFileSizeLimit(): any {
    return 20; /* in MB */
  }
  static cropperSettings(): CropperSettings {
    let cropperSettings = new CropperSettings();
    cropperSettings.cropOnResize = true;
    cropperSettings.noFileInput = true;

    cropperSettings.width = 140;
    cropperSettings.height = 150;

    cropperSettings.croppedWidth = 140;
    cropperSettings.croppedHeight = 150;

    cropperSettings.canvasWidth = 140;
    cropperSettings.canvasHeight = 150;

    cropperSettings.minWidth = 50;
    cropperSettings.minHeight = 40;

    cropperSettings.cropperDrawSettings.strokeColor = '#fff';
    cropperSettings.cropperDrawSettings.strokeWidth = 2;

    cropperSettings.rounded = false;
    cropperSettings.keepAspect = true;
    cropperSettings.touchRadius = 0;
    cropperSettings.centerTouchRadius = 20;
    cropperSettings.fileType = 'file';
    return cropperSettings;
  }

  static getWeekday() {
    return [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
  }

  static showOptions(email) {
    let emails = ['joseph.raj@techuplabs.com', 'sam@flocksy.com'];
    let value;
    if (emails.includes(email)) {
      value = true;
    } else {
      value = false;
    }
    return value;
  }

  static isValidJson(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
}
