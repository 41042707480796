import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { API_CONSTANTS } from '../common/constant/ApiConstants';
import { CustomHttpService } from './custom.http.service';
import { LocalStorageService } from './localstorage.service';

@Injectable()
export class GroupService {
  constructor(
    private _customHttpService: CustomHttpService,
    private _localStorageService: LocalStorageService
  ) {}

  getGroupList(workspaceId: any) {
    return this._customHttpService.get(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/groups'
    );
  }

  getUserGroupList(workspaceId: any) {
    return this._customHttpService.get(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/users/' +
        this._localStorageService.getUserId() +
        '/groups'
    );
  }

  getGroupDetails(workspaceId: any, id) {
    return this._customHttpService.get(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/groups/' +
        id
    );
  }

  deleteGroup(workspaceId: any, id) {
    return this._customHttpService.delete(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/groups/' +
        id
    );
  }

  postGroupList(workspaceId: any, oData: object) {
    return this._customHttpService.post(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/groups',
      oData
    );
  }

  editGroupList(workspaceId: any, oData: object, id) {
    return this._customHttpService.put(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/groups/' +
        id,
      oData
    );
  }

  postGroupInUser(workspaceId: any, id: any, oData?) {
    return this._customHttpService.post(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/groups/' +
        id +
        '/users',
      oData
    );
  }

  deleteGroupInUser(workspaceId: any, id: any, oData?) {
    return this._customHttpService.deleteWithBody(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/groups/' +
        id +
        '/users',
      oData
    );
  }

  getGroupInUser(workspaceId: any, id: any) {
    return this._customHttpService.get(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/groups/' +
        id +
        '/users'
    );
  }

  getPendingInviteUser(workspaceId: any, id: any) {
    return this._customHttpService.get(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/groups/' +
        id +
        '/pending-invite-users'
    )
  }

  postPendingInviteUser(workspaceId: any, id:any, oData?) {
    return this._customHttpService.post(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/groups/' +
        id +
        '/pending-invite-users',
        oData
    )
  }

  deletePendingInviteUser(workspaceId: any, id: any, oData?) {
    return this._customHttpService.deleteWithBody(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/groups/' +
        id +
        '/pending-invite-users',
        oData
    )
  }


}
