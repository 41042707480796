<nav
  [ngClass]="!workspaceId ? 'topnavbar' : 'topnavbar-workspace'"
  class="navbar navbar-expand-lg navbar-light"
  role="navigation"
>
  <div class="row header-wrapper">
    <div
      [ngClass]="isVisible ? 'col-6' : 'col-12'"
      class="order-2 order-md-1 col-8 col-md-3 col-lg-2 p-0 align-items-center header-logo"
    >
      <ul class="navbar-nav mr-auto flex-row sidebar-wrapper">
        <li class="nav-item">
          <a
            (click)="
              settings.toggleLayoutSetting('asideToggled');
              $event.stopPropagation()
            "
            [ngStyle]="{ display: onlyLogo === true ? 'none' : 'block' }"
            class="nav-link sidebar-toggle d-md-none text-dark"
          >
            <em class="fas fa-bars"></em>
          </a>
        </li>
      </ul>
      <div
      [ngClass]="!isVisible ? 'logo-style' : ''"
       class="navbar-header">
        <a
          class="navbar-brand"
          (click)="onClick()"
        >
          <div class="brand-logo">
            <img
              class="img-fluid img-logo"
              src="assets/img/daybeam-logo.png"
              alt="App Logo"
            />
          </div>
        </a>
      </div>
    </div>
    <div
      *ngIf="isVisible"
      class="order-1 order-md-1 col-md-7 col-lg-6 d-lg-flex col-12 p-0 workspace-menu"
    >
      <div
        *ngIf="workspaceId"
        class="workspace-dropdown-wrapper"
        [formGroup]="workspaceGroup"
      >
        <ng-select
          #select
          (change)="onChangeWorkspace($event)"
          [items]="WorkspaceList"
          bindLabel="id"
          bindValue="name"
          [searchable]="false"
          placeholder=""
          formControlName="workspaceName"
        >
          <ng-template ng-label-tmp let-item="item">
            <div class="workspace-dropdown d-flex align-items-center">
              <span class="workspace-title">
                <img
                  src="../../../assets/img/laptop-solid.svg"
                  class="user-icon-select"
                />
                <span class="workspace-title-name" title="{{ item.name }}">{{
                  item.name
                }}</span>
              </span>
              <em class="fa fa-angle-down arrow-icon" aria-hidden="true"></em>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="workspace-dropdown-option">
              <div [ngClass]="item.name === selectedWorkspaceName ? 'selcted-workspace' : ''" class="workspace-list">
                <img
                  *ngIf="!item?.users_count"
                  src="../../../assets/img/user-tie-solid.svg"
                  class="user-icon-option"
                />
                <img
                  *ngIf="!!item?.users_count"
                  src="../../../assets/img/invited-workspaces.svg"
                  class="user-icon-option"
                />
                <span class="workspace-option-title" title="{{ item.name }}">{{
                  item.name
                }}</span>
              </div>
            </div>
          </ng-template>
          <ng-template ng-footer-tmp>
            <div
              (click)="closeSelect(select)"
              class="d-flex manage-workspace-title"
            >
              <em class="fa fa-cog manage-workspace-icon"></em>
              <span
                class="manage-workspace-option-title"
                routerLink="/workspaces"
              >
                Manage Workspace
              </span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div
        *ngIf="isVisible && workspaceId && isOnDashboard"
        id="earningTimer"
        class="timer-wrapper order-3 align-items-center"
      >
        <app-earning-timer
          [userRoleId]="userRoleId"
        ></app-earning-timer>
      </div>
    </div>

    <div
      class="order-2 profile-dropdown order-md-1 col-4 col-md-2 col-lg-3 p-0 col-xl-3 ml-lg-auto"
      *ngIf="!!loggedInUser"
    >

      <ul
        class="navbar-nav flex-row navbar-menu"
        [ngStyle]="{ visibility: onlyLogo === true ? 'hidden' : 'show' }"
      >
        <li class="nav-item feedback d-flex align-items-center">
          <a #feedbackBtnDesktop id="header-feedback-btn" class="nav-link text-dark text-lg" data-feedspace="gvH6ujAWwUll6Js6"
            href="javascript:">
            <span class="d-none d-sm-block">
              <em class="fas fa-bullhorn mr-1"></em>
              Feedback
            </span>
            <span class="d-block d-sm-none">
                <em class="fas fa-bullhorn mr-1"></em>
            </span>
          </a>
        </li>
        <li class="nav-item dropdown dropdown-list" dropdown id="profileicon">
          <a
            class="nav-link dropdown-toggle profile-dropdown-list text-dark"
            dropdownToggle
            href="javascript:"
          >
            <img
              [src]="profileImageUrl"
              onError="this.src='assets/img/user-placeholder.png';"
              class="img-fluid header-image rounded-circle thumb24 mr-2"
            />
            <span class="d-none d-lg-inline text-bold user-name-wrapper">
              {{ _localStorageService.getUserFullName() }}
            </span>
          </a>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right shadow">
            <div class="dropdown-item">
              <div class="list-group">
                <div class="list-group-item list-group-item-action">
                  <a [routerLink]="'/profile'" class="profile-menu">
                    <span class="x align-items-center menu-item">
                      Profile
                    </span>
                  </a>
                </div>
                <div class="list-group-item list-group-item-action">
                  <a [routerLink]="'/support'" class="profile-menu">
                    <span class="3x align-items-center menu-item">
                      Support
                    </span>
                  </a>
                </div>

                <div class="list-group-item list-group-item-action">
                  <a [routerLink]="'/pricing'" class="profile-menu">
                    <span class="3x align-items-center menu-item">
                      Billing
                    </span>
                  </a>
                </div>

                <div class="list-group-item list-group-item-action">
                  <a [routerLink]="'/logout'" class="profile-menu">
                    <span class="d-flex align-items-center menu-item">
                      Log Out
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
