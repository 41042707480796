import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { LocalStorageService } from 'src/app/services/localstorage.service';
import { WorkspaceInviteUserService } from 'src/app/services/workspace-invite-user.service';
import { WorkspaceService } from 'src/app/services/workspace.service';

@Component({
  selector: 'app-workspace-invite-users',
  templateUrl: './workspace-invite-users.component.html',
  styleUrls: ['./workspace-invite-users.component.scss'],
})
export class WorkspaceInviteUsersComponent implements OnInit {
  userDetails: any;
  token: string | null = '';
  signupId: any;

  constructor(
    private _inviteuserService: WorkspaceInviteUserService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _localStorageService: LocalStorageService,
    private _workspaceService: WorkspaceService,
    private _toasterService: ToasterService
  ) {
    if (this._activatedRoute.snapshot.paramMap.get('token')) {
      this.token = this._activatedRoute.snapshot.paramMap.get('token');
    }
  }

  ngOnInit(): void {
    this._inviteuserService.getTokenExits(this.token).subscribe((res) => {
      this.userDetails = res.data;
      this._workspaceService.email.next(res.data);
      let currentUser = this._localStorageService.getItem('current_user');

      if (this.userDetails.emailUser != null) {
        if (this._localStorageService.isUserLoggedIn() && res.data.email === currentUser?.email ) {
          this._localStorageService.setUserData(res.data);
          this._localStorageService.setItem('invite_token_details', res.data);
          this._localStorageService.setItem('invite_token', res.data.token);
          this._router.navigate(['/workspaces/pending-invitations']);
        } else {
          if(this._localStorageService.isUserLoggedIn()) {
            this._toasterService.pop( "error","login as " + res?.data?.email)
            }
          this.updateTokenData(res);
          this._router.navigate(['/login']);
        }
      } else {
        if (this._localStorageService.isUserLoggedIn()) {
          this._toasterService.pop('error', 'login as ' + res?.data?.email);
        }
        this.updateTokenData(res);
        this._router.navigate(['/signup', this.token]);
      }
    },
    (error) => {
      this._localStorageService.resetSessionStorage();
      this._router.navigate(['/login']);
    });
  }

  updateTokenData(res){
    this._localStorageService.resetSessionStorage();
    this._localStorageService.setUserData(res.data);

    this._localStorageService.setItem('invite_token_details', res.data);
    this._localStorageService.setItem('invite_token', res.data.token);
  }
}
