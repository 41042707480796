import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DaybeamUtil } from '../common/DaybeamUtil';
import { BehaviorSubject } from 'rxjs';

declare var window: any;
@Injectable()
export class LocalStorageService {
  profilePictureChanged = new BehaviorSubject('');

  getItem(key: string, defaultValue?: any): any {
    if (localStorage.getItem(key)) {
      let data: any;
      if (typeof localStorage.getItem(key) !== 'undefined') {
        try {
          data = JSON.parse(localStorage.getItem(key) as any);
          return data;
        } catch (e) {
          data = '';
          return data;
        }
      } else {
        return '';
      }
    } else {
      return defaultValue;
    }
  }

  setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  setAccessToken(access_token: string): void {
    this.setItem('access_token', access_token);
  }

  getAccessToken(): string {
    return this.getItem('access_token', '');
  }

  setRefreshToken(refresh_token: string): void {
    this.setItem('refresh_token', refresh_token);
  }

  getRefreshToken(): string {
    return this.getItem('refresh_token', '');
  }

  setSessionToken(token: string): void {
    this.setItem('session_token', token);
  }

  getSessionToken(): string {
    return this.getItem('session_token', '');
  }

  setUserLoggedIn(logged_in: boolean): void {
    if (typeof logged_in === 'undefined') {
      logged_in = false;
    }
    this.setItem('is_logged_in', logged_in);
  }

  isUserLoggedIn(): boolean {
    return this.getItem('is_logged_in', false);
  }

  setCurrentUser(current_user: object): void {
    this.setItem('current_user', current_user);
  }

  setWorkspace(workspace: object): void {
    this.setItem('workspace', workspace);
    // this.setItem('role_id', workspace['role_Id'])
  }

  getInviteToken(): any {
    return this.getItem('invite_token');
  }

  getCurrentUser(): object {
    return this.getItem('current_user', false);
  }

  getWorkspace(): object {
    return this.getItem('workspace');
  }

  setUserData(data: any): void {
    this.setItem('userData', data);
  }

  resetSessionStorage(): void {
    this.removeItem('access_token');
    this.removeItem('current_user');
    this.removeItem('is_logged_in');
    this.removeItem('prevPageId');
    this.removeItem('role_Id');
    this.removeItem('auth_seamless');
    this.removeItem('invite_token_details');
    this.removeItem('invite_token');
    this.removeItem('workspace');
    this.removeItem('refresh_token');
    this.removeItem('userData');
  }

  getUserAuthSeamLess() {
    return this.getItem('auth_seamless', false);
  }

  setUserFullName(name) {
    let oUser = this.getCurrentUser();
    if (oUser) {
      oUser['name'] = name;
    }
    this.setItem('current_user', oUser);
  }

  getUserFullName(): string {
    let oUser = this.getCurrentUser();
    if (oUser) {
      return oUser['name'];
    }
    return '';
  }
  getUserEmail(): string {
    let oUser = this.getCurrentUser();
    if (oUser) {
      return oUser['email'];
    }
    return '';
  }
  getUserType(): string {
    if (this.getCurrentUser()) {
      if (this.getCurrentUser()['is_admin']) {
        return 'Admin';
      } else if (this.getCurrentUser()['is_client']) {
        return 'Client';
      } else if (this.getCurrentUser()['is_sub_client']) {
        return 'SubClient';
      } else if (this.getCurrentUser()['is_designer']) {
        return 'Designer';
      } else if (this.getCurrentUser()['is_project_manager']) {
        return 'ProjectManager';
      }
    }
    return '';
  }
  getUserId(): string {
    var user_id = this.getItem('user_id', '');
    if (user_id == '') {
      let oUser = this.getCurrentUser();
      if (oUser) {
        return oUser['id'];
      }
    }
    return user_id;
  }
  formatDate(value: any, format: string = '', isDayRequired): any {
    var oMomentUtc = moment.utc(value).toDate();
    if (isDayRequired) {
      let day = DaybeamUtil.getWeekday()[oMomentUtc.getDay()];
      return (
        day +
        ', ' +
        moment(oMomentUtc, 'YYYY-MM-DD hh:mm:ss').local().format(format)
      );
    }
    return moment(oMomentUtc, 'YYYY-MM-DD hh:mm:ss').local().format(format);
  }

  formatedDate(value: any, format: string = '', isDayRequired): string {
		var oMomentUtc = moment.utc(value).toDate();
    if(isDayRequired){
      return (
        moment(oMomentUtc, 'YYYY-MM-DD hh:mm:ss').format(format)
      );
    }
		return moment(oMomentUtc, 'YYYY-MM-DD hh:mm:ss').format(format);
	}

  formatCalendarDate(value: any): any {
    var today = moment().startOf('day');
    var date = moment(value).startOf('day');
    var diff = today.diff(date, 'days');
    
    if (diff === 0) {
      return 'Today';
    } else if (diff === 1) {
      return 'Yesterday';
    } else if (diff < 30) {
      return diff + ' days ago';
    } else {
      return date.format('YYYY-MM-DD');
    }
  }

  getProfileImageURL() {
    if (this.getCurrentUser()) {
      if (this.getCurrentUser()['profile_file_url']) {
        if (typeof this.getCurrentUser()['profile_file_url'] != 'undefined') {
          return this.getCurrentUser()['profile_file_url'];
        }
      }
    }
    return 'avatar.png';
  }

  getRoleId() {
    return this.getItem('role_Id');
  }

  getEditorConfig() {
		return {
			placeholder: 'Your Comment',
			toolbar: [],
			popover: {
				// this is needed to hide the popover from appearing automatically
				image: [],
				link: [],
				air: [],
			},
			options: {
				modules: {
					autoLink: false,
				},
			},
			styleTags: [], //will be used to provide unique id to all the instance for summernote emoji editor
			callbacks: {
				onPaste: function (e) {
					var bufferText = (
						(e.originalEvent || e).clipboardData ||
						window.clipboardData
					).getData('Text');
					e.preventDefault();
					document.execCommand('insertText', false, bufferText);
				},
				onKeyup: function (e) {
					e.preventDefault();
				},
			},
			disableDragAndDrop: true
		};
	}

  getUserEditorConfig() {
		return {
			placeholder: 'Your Comment',
			height: '100px',
		toolbar: [
			['font', ['bold', 'italic', 'underline']],
			['para', ['ul', 'ol']],
		],
			popover: {
				// this is needed to hide the popover from appearing automatically
				image: [],
				link: [],
				air: [],
			},
			options: {
				modules: {
					autoLink: false,
				},
			},
			styleTags: [], //will be used to provide unique id to all the instance for summernote emoji editor
			callbacks: {
				onPaste: function (e) {
					var bufferText = (
						(e.originalEvent || e).clipboardData ||
						window.clipboardData
					).getData('Text');
					e.preventDefault();
					document.execCommand('insertText', false, bufferText);
				},
				onKeyup: function (e) {
					e.preventDefault();
				},
			},
			disableDragAndDrop: true
		};
	}
}
