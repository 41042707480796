import { ToasterService } from 'angular2-toaster';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { LocalStorageService } from './localstorage.service';
import { Router } from '@angular/router';
import { ResponseMessages } from '../common/constant/ResponseMessages';

@Injectable()
export class CustomHttpService {
  url!: any;
  constructor(
    private _httpClient: HttpClient,
    private _localStorageService: LocalStorageService,
    public _router: Router,
    public _toasterService: ToasterService,
  ) {
   this.url = window.location.href;
  }

  get(url: string): Observable<any> {
    return this._httpClient
      .get(url, { headers: this.getHttpHeaders() })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.catchError(err);
        }
      ))
  }

  postFormData(url: string, oFiles) {
    let formData: FormData = new FormData();
    formData.append('file_category_id', 'PROFILE_PICTURE');

    for (let i = 0; i < oFiles.length; i++) {
      const file = oFiles[i];
      formData.append('files[0][file]', file);
    }

    return this._httpClient
      .post(url, formData, {
        headers: this.getFormDataHttpHeaders(),
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round(100 * event.loaded);
              return { status: 'progress', progress: progress };
            case HttpEventType.Response:
              return event.body;
            default:
              return 'Unhandled event: ${event.type}';
          }
        }),
        catchError((err: HttpErrorResponse) => {
          return this.catchError(err);
        })
      )
  }

  postImgProfile(url: string, body: any): Observable<any> {
    return this._httpClient
      .post(url, body, {
        headers: this.getFormDataHttpHeaders(),
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.catchError(err);
        }
      ))
  }

  post(url: string, body: any): Observable<any> {
    return this._httpClient
      .post(url, body, { headers: this.getHttpHeaders() })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.catchError(err);
        }
      ))
  }

  put(url: string, body: any): Observable<any> {
    return this._httpClient
      .put(url, body, { headers: this.getHttpHeaders() })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.catchError(err);
        }
      ))
  }

  delete(url: string): Observable<any> {
    return this._httpClient
      .delete(url, { headers: this.getHttpHeaders() })
     .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.catchError(err);
        }
      ))
  }

  deleteWithBody(url: string, body: any): Observable<any> {
    return this._httpClient
      .request('DELETE', url, { headers: this.getHttpHeaders(), body: body })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.catchError(err);
        }
      ))
  }

  catchError(err) {
    if (err.status == 401) {
      if (this._localStorageService.isUserLoggedIn()) {
      this._localStorageService.resetSessionStorage();
        this._router.navigate(['/login']);
      }
    }
    if (err.error && err.error.code) {
      this.showErrorMessage(err.error.code);
    } else if (err.status == 403) {
      let message = 'Access Denied';
      if (err.error && err.error.message) {
        message = err.error.message;
      }
      this._toasterService.pop('error', message);
      this._router.navigate(['/dashboard']);
    } else if (err.status == 404) {
      this._toasterService.pop('error', 'Resource Not Found');
    } else {
      let message = '';
      if (err) {
        if (err.error) {
          if (err.error.message) {
            message = err.error.message;
          }
          if ('errors' in err.error) {
            message = '';
            for (let field of Object.keys(err.error.errors)) {
              let arr: any = [];
              err.error.errors[field].forEach((code) => {
                if (ResponseMessages[code]) {
                  arr.push(ResponseMessages[code]);
                } else {
                  arr.push("Error. Please check with support");
                }
              });
              message += arr.join(', ') + ' ';
            }
          }
        } else if (err.message) {
          message = err.message;
        }
      }
      if (message) {
        this._toasterService.pop('error', message);
      }
    }
    return throwError(err);
  }

  showErrorMessage(code) {
    if (ResponseMessages && ResponseMessages[code]) {
      this._toasterService.pop('error', ResponseMessages[code]);
    } else {
      this._toasterService.pop('error', "Error. Please check with support");
    }
  }

  getHttpHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('X-REQUEST-TYPE', 'WEB');
    headers = headers.set('X-ACTIVE-TAB-URL',this.url);
    if (this._localStorageService.getAccessToken()) {
      headers = headers.set(
        'Authorization',
        'Bearer ' + this._localStorageService.getAccessToken()
      );
    }
    if (this._localStorageService.getSessionToken()) {
      headers = headers.set(
        'X-Auth-Token',
        this._localStorageService.getSessionToken()
      );
      headers = headers.set('X-REQUEST-TYPE', 'WEB');
    }
    return headers;
  }

  getFormDataHttpHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    if (this._localStorageService.getAccessToken()) {
      headers = headers.set(
        'Authorization',
        'Bearer ' + this._localStorageService.getAccessToken()
      );
      headers = headers.set('X-REQUEST-TYPE', 'WEB');
    }
    return headers;
  }
}
