import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimerSharedService {
  addedNewTask: BehaviorSubject<any> = new BehaviorSubject<any>('');


  constructor() {}
}
