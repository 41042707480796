import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class ApiService {
  constructor(private _customHttpService: CustomHttpService) {}

  getAuthSeamless(): Observable<any> {
    return this._customHttpService.get(API_CONSTANTS.AUTH_SEAMLESS);
  }

  getAuthUser(oData?): Observable<any> {
    return this._customHttpService.get(API_CONSTANTS.OAUTH_URL + '/me');
  }

  logout(): Observable<any> {
    return this._customHttpService.post(API_CONSTANTS.LOGOUT, '');
  }
}
