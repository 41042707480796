import { DaybeamValidator } from './../../../common/validation/daybeam-validator';
import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { SignupService } from 'src/app/services/signup.service';
import { LocalStorageService } from 'src/app/services/localstorage.service';

@Component({
  selector: 'app-password-reset-token',
  templateUrl: './password-reset-token.component.html',
  styleUrls: ['./password-reset-token.component.scss'],
})
export class PasswordResetTokenComponent implements OnInit {
  tokenExist = false;
  token: any = '';
  valForm!: FormGroup;
  oError = {
    status: '',
    error: {
      message: '',
    },
  };
  serverStatusCode: number = 0;
  serverMessage: string = '';
  passwordResetTokenLoading: boolean = false;

  constructor(
    public _settingsService: SettingsService,
    public _signupService: SignupService,
    public _formBuilder: FormBuilder,
    public _activatedRoute: ActivatedRoute,
    public _toasterService: ToasterService,
    private _router: Router,
    public _localStorageService: LocalStorageService
  ) {
    this._activatedRoute.params.subscribe((val) => {
      this.token = this._activatedRoute.snapshot.paramMap.get('token');
    });
  }

  ngOnInit() {
    if (this._localStorageService.isUserLoggedIn()) {
      this.checkUserLoggedIn();
    } else {
      this.getToken();
    }
  }

  checkUserLoggedIn() {
    if (this._localStorageService.isUserLoggedIn()) {
      this._router.navigate(['/dashboard']);
    }
  }
  initForm() {
    this.valForm = this._formBuilder.group(
      {
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(16),
          ]),
        ],
        confirm_password: ['', Validators.required],
      },
      {
        validator: DaybeamValidator.equalTo('password', 'confirm_password'),
      }
    );
  }

  getToken(): void {
    this._signupService.checkResetToken(this.token).subscribe(
      (data) => {
        this.token = data.data.token;
        this.tokenExist = true;
        this.initForm();
      },
      (err) => {
        this.oError = err;
      }
    );
  }

  submitForm($event) {
    this.passwordResetTokenLoading = true;
    $event.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }

    if (!this.valForm.valid) {
      return;
    }

    let token = this.token;

    this._signupService.storeResetToken(this.valForm.value, token).subscribe(
      (data) => {
        this.passwordResetTokenLoading = false;
        this.valForm.reset();
        let msg = data.message
          ? data.message
          : 'Token Reset Successful';
        this._toasterService.pop('success', msg);
        this.serverStatusCode = data.status;
        this.serverMessage = data.message;
      },
      (err) => {
        this.oError = err;
        this.passwordResetTokenLoading = false;
      }
    );
  }
}
