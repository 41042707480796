<div class="wrapper">
  <app-header class="topnavbar-wrapper" [onlyLogo]="true"></app-header>
  <div class="main_wrapper">
    <!-- START card-->
    <h1 class="login_title">{{ "labels.reset_password" | translate }}</h1>

    <div class="card-main">
      <div class="card-body">
        <p class="m_b_0 text-black">
          {{ "labels.forgot_password" | translate }}
        </p>
        <p class="text-black">
          {{
            "labels.dont_worry!_just_enter_your_email_and_we'll_help_you_out"
              | translate
          }}
        </p>
        <!-- <div *ngIf="oError">
          <alert *ngIf="oError?.message" [dismissible]="true" type="danger">
            {{ oError.message }}
          </alert>
        </div> -->
        <form
          (submit)="submitForm($event)"
          *ngIf="valForm"
          [formGroup]="valForm"
          class="form-validate form-main d-flex justify-content-between align-items-center"
          name="recoverForm"
          novalidate=""
          role="form"
        >
          <div class="form-group w-100 pr-5">
            <label class="label-required text-black" for="email">{{
              "labels.email" | translate
            }}</label>
            <span class="text-danger pl-1">*</span>
            <div class="input-group with-focus">
              <input
                autocomplete="off"
                class="form-control border-right-0"
                formControlName="email"
                name="email"
                placeholder="Email"
                type="email"
                autocomplete="off"
              />
              <div class="input-group-append border-left">
                <span class="input-group-text text-muted bg-white">
                  <em class="fa fa-envelope label-icons"></em>
                </span>
              </div>
            </div>
            <div
              *ngIf="
                valForm.controls['email'].hasError('required') &&
                (valForm.controls['email'].dirty ||
                  valForm.controls['email'].touched)
              "
              class="text-danger"
            >
              {{ "errors.required" | translate }}
            </div>
            <div
              *ngIf="
                valForm.controls['email'].hasError('email') &&
                (valForm.controls['email'].dirty ||
                  valForm.controls['email'].touched)
              "
              class="text-danger"
            >
              {{ "errors.valid_email" | translate }}
            </div>
          </div>
          <button
            [disabled]="!valForm.valid || resetPasswordLoading"
            class="btn btn-block btn-primary button-reset"
            type="submit"
          >
            {{ "labels.reset_password" | translate }}
          </button>
        </form>
        <div class="d-flex justify-content-center mt-4">
          <span class="pr-2"><i class="fas fa-user-tie text-black"></i></span>
          <p class="text-black">{{ "labels.dont_have_account" | translate }}</p>
        </div>
        <div class="d-flex justify-content-center font-weight-bolder">
          <a [routerLink]="'/signup'"
            ><u>{{ "labels.signup_header" | translate }}</u></a
          >
        </div>
        <div *ngIf="serverMessage" class="mt-3">
          <div *ngIf="serverStatusCode == 200">
            <div class="alert alert-success">
              {{ serverMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
