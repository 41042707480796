import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-advanced-header',
  templateUrl: './advanced-header.component.html',
  styleUrls: ['./advanced-header.component.scss'],
})
export class AdvancedHeaderComponent implements OnInit {
  constructor(private router: Router) {}
  @Input() menuItems: any;
  @Output() onModelOpen = new EventEmitter<string>();

  currentRoute!: string;

  ngOnInit(): void {
    this.currentRoute = this.router?.url;
  }

  advancedHeader() {
    this.onModelOpen.emit(this.currentRoute);
  }
}
