import { Injectable } from '@angular/core';
import { CustomHttpService } from './custom.http.service';
import { API_CONSTANTS } from '../common/constant/ApiConstants';

@Injectable({
  providedIn: 'root',
})
export class PendingInvitationService {
  constructor(private _customHttpService: CustomHttpService) {}

  getPendingInvitation() {
    return this._customHttpService.get(
      API_CONSTANTS.API_ENDPOINT_URL + '/api/workspace-invite-users'
    );
  }

  joinWorkspace(workspaceId: any, token: any, oData: any) {
    return this._customHttpService.put(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/invite-users/' +
        token +
        '/actions/join',
      oData
    );
  }

  declineWorkspace(workspaceId: any, token: any) {
    return this._customHttpService.delete(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/invite-users/' +
        token
    );
  }
}
