import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/common/api.service';
import { LocalStorageService } from 'src/app/services/localstorage.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  user: object = {};

  constructor(
    private _localStorageService: LocalStorageService,
    private _router: Router,
    private _apiService: ApiService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.init(next, state);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.init(next, state);
  }

  init(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var oSubject = new Subject<boolean>();
    if (
      ((!!next.data.unauthorize &&
        this._localStorageService.getRoleId() !== next.data.unauthorize) ||
        !next.data.unauthorize) &&
      this._localStorageService.isUserLoggedIn()
    ) {
      this._apiService.getAuthSeamless().subscribe(
        (data) => {
          oSubject.next(true);
        },
        (err) => {
          this._localStorageService.resetSessionStorage();
          this._router.navigate(['/login']);
          oSubject.next(false);
        }
      );
    } else if (this._localStorageService.isUserLoggedIn()) {
      if (this._localStorageService.getRoleId() === next.data.unauthorize) {
        if (state.url !== '/dashboard') {
          this._router.navigate(['/dashboard']);
        }
      } else {
        this._apiService.getAuthSeamless().subscribe(
          (data) => {
            if (state.url !== '/dashboard') {
              this._router.navigate(['/dashboard']);
            }
            oSubject.next(true);
          },
          (err) => {
            this._localStorageService.resetSessionStorage();
            this._router.navigate(['/login']);
            oSubject.next(false);
          }
        );
      }
    } else {
      this._localStorageService.resetSessionStorage();
      this._router.navigate(['/login']);
      oSubject.next(false);
    }
    return oSubject.asObservable().pipe(first());
  }
}
