import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/services/localstorage.service';

import { UserblockService } from './userblock.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-userblock',
  templateUrl: './userblock.component.html',
  styleUrls: ['./userblock.component.scss'],
})
export class UserblockComponent implements OnInit {
  user: any;
  profileImageUrl: string = '';
  unsubscribe$ = new ReplaySubject<any>(10);

  constructor(
    public userblockService: UserblockService,
    public _localStorageService: LocalStorageService,
  ) {
    this.user = {
      picture: 'assets/img/user-placeholder.png',
    };
  }

  ngOnInit() {
    this.initSubscription();
  }

  userBlockIsVisible() {
    return this.userblockService.getVisibility();
  }
  initSubscription() {
    this.profileImageUrl = this._localStorageService.getProfileImageURL();

    this._localStorageService.profilePictureChanged
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((data) => {
      this.profileImageUrl = this._localStorageService.getProfileImageURL();
    });
  }

  ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
