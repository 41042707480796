import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TimeEntriesService } from 'src/app/services/time-entries/time-entries.service';
import { TimerSharedService } from 'src/app/services/time-entries/timer-shared.service';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { BsModalService } from 'ngx-bootstrap';

import { ReplaySubject, throwError } from 'rxjs';
import { catchError, debounceTime, takeUntil } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/services/localstorage.service';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { DateTime } from 'ts-luxon';
import { GroupService } from 'src/app/services/group.service';
import { CommonService } from 'src/app/services/common/common.service';
import { DaybeamUtil } from 'src/app/common/DaybeamUtil';
import { TransactionsService } from 'src/app/services/transactions.service';
import * as moment from 'moment';

@Component({
  selector: 'app-earning-timer',
  templateUrl: './earning-timer.component.html',
  styleUrls: ['./earning-timer.component.scss'],
})
export class EarningTimerComponent implements OnInit, AfterViewInit,OnDestroy {
  activeTimer = true;
  @Input() userRoleId;
  @Input() workspaceArchived;
  runningData: any;
  workspaceId: any;
  bsValue = new Date();
  manualTab: boolean = false;
  isActiveTab: boolean = true;
  isModalLoading: boolean = false;
  primaryUserRoleId = DaybeamUtil.PrimaryUser;
  @ViewChild('search') searchElement!: ElementRef;
  isManualModalLoading: boolean = false;
  isSwitchmodalLoading: boolean = false;
  isStartTimerLoading: boolean = false;
  isSwitchTaskLoading: boolean = false;
  isBtnLoading: boolean = false;
  isManualBtnLoading: boolean = false;
  isSwitchBtnLoading: boolean = false;
  private searchDataSubscribe: any = null;
  isStopTimerLoading: boolean = false;
  countDown;
  showSwitchTimer: boolean = false;
  currentDate: any;
  timerEntryId: any;
  searchData: any = [];
  @Input() timerText: any;
  TimerForm!: FormGroup;
  groupList: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  countDownTime: any;
  searchValue: any;
  searchLength: any;
  selectedTask: any;
  selectedTaskName: any;
  userTaskList: any;
  userGroupList: any = [];
  userGroupListData: any = [];
  selectedTaskGroupId: any;
  selectedSwitchTask: any;
  autoTimerTextValue: any;
  workspaceTimerText: any;
  runningTimerText: any;
  groupLoaded: boolean = false;
  unsubscribe$ = new ReplaySubject<any>(10);
  isValid: boolean = true;selectedNewTask: any;
  startTimerApiRes: any;
  roleId: any;
  groupId: any;
  totalTransactionTime;
  datePickerConfig: any = {
    isAnimated: true,
    dateInputFormat: 'MMM DD, YYYY',
    showWeekNumbers: false,
  };
  groupExist: boolean = false;
  taskExist: any;
  startDateTime!: Date;
  endDateTime!: moment.Moment;
  currentTime!: DateTime;
  endTime: any;
  @Input() fromDashboard: boolean = false;
  isPopOverOpen: boolean = true;
  timerDataLoaded: boolean = false;
  newUser: boolean = true;
  newUserTaskStopped: boolean = false;
  @Output() setupTaskStopped = new EventEmitter<boolean>();
  switchedToManualOnDashboard: boolean = false;
  showTask: boolean = false;
  @Input() fromDashboardNotNewUser: boolean = false;
  timerSwitchedOnDashboard: boolean = false;
  @Output() timerDataLoadedDashboard = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    public _localStorageService: LocalStorageService,
    public _bsModalService: BsModalService,
    private _formBuilder: FormBuilder,
    public _toasterService: ToasterService,
    public _timerSharedService: TimerSharedService,
    private _workspaceService: WorkspaceService,
    private _timeEntriesService: TimeEntriesService,
    private _groupService: GroupService,
    private _commonService: CommonService,
    private _transactionService: TransactionsService
  ) {
    router.events.subscribe((val) => {
      this.closeTimerDropdown();
      this.isModalLoading = false;
      this.isManualModalLoading = false;
      this.isSwitchmodalLoading = false;
    });
  }

  ngOnInit() {
    this.roleId = this._localStorageService.getRoleId();
    let minute: any = document.getElementById('minutes');
    if (minute) {
      if (minute.value > 59) {
        minute.value = '59';
      } else if (minute.value < 0) {
        minute.value = '0';
      }
    }
    this._workspaceService.workspaceId
    .pipe(takeUntil(this.destroyed$))
    .subscribe((res) => {
      this.workspaceId = res?.id;
      clearInterval(this.timerText);
      this.userGroupListData = [];
      this.userGroupList = [];
      this.groupLoaded = false;
      this.groupList = [];

      if (this._localStorageService.isUserLoggedIn() && this.workspaceId) {
        this.runningTimer(this.workspaceId);
      }
    });

    this.initForms();
    this._workspaceService.startTimer.subscribe((res)=>{
      if(!!res){
        this.totalTransactionTime = res?.totalHour;
       this.openTimerModel();
      }
    })
    if (this.fromDashboardNotNewUser) {
      this.newUserTaskStopped = true;
      this.timerDataLoaded = false;
      this.showTask = true;
      this.newUser = false;
      this.isPopOverOpen = false;
    }
  }

  runningTimer(e) {
    clearInterval(this.timerText);
    clearInterval(this.runningTimerText);
    this._timeEntriesService.runningTimerEntry(e).subscribe((res) => {
      if(res?.data) {
      this.timerEntryId = res.data.id;
      this.selectedTaskName = res.data.description;
      var date = new Date(res.data.starts_at);
      const runningTimerText = date.getTime();
      this.workspaceTimerText = DateTime.utc().valueOf() - runningTimerText;

      this.runningTimerText = setInterval(() => {
        this.workspaceTimerText = DateTime.utc().valueOf() - runningTimerText;
        this.countDownTime = this._commonService.getTimeInHHMMSS(
          this.workspaceTimerText / 1000
        );
      }, 1000);

      this.countDownTime = this._commonService.getTimeInHHMMSS(
        this.workspaceTimerText / 1000
      );
      this.runningData = res.data;
      this.isStartTimerLoading = !!this.runningData?.id;
      if(!!res?.data?.id){
        this.starTimerFromWeb();
      } else {
        this.stopTimerFromWeb();
      }
      }
      this.timerDataLoaded = true;
      this.timerDataLoadedDashboard.emit();
      if (this.fromDashboard && this.newUser) {
        // this.startAutoTimer();
      }
    });
  }

  ngAfterViewInit(): void {
    this.searchElement.nativeElement.focus();
  }

  openTimerModel() {
    this.groupList = [];
    this.groupLoaded = false;
    let timerTab: any = document.getElementById('timerTab');
    timerTab.style.display = 'block';
    let timerBtn = document.getElementById("dropdownMenuLink");
    if(timerBtn){
      timerBtn.style.backgroundColor = "#077fbd"
    }
    this.TimerForm.get('autoTimerForm')?.setValue('');
    this.TimerForm.get('switchTimerForm')?.setValue('');
    this.TimerForm.get('manualHours')?.setValue('');
    this.TimerForm.get('manualMinutes')?.setValue('');
    this.TimerForm.get('startDate')?.setValue('');
    this.searchData = [];
    this.searchLength = 0;
    this.userGroupList = [];
    this.isBtnLoading = false;
    this.isManualBtnLoading = false;
    this.isSwitchBtnLoading = false;
    if(!!this.workspaceId){
      clearInterval(this.timerText);
    clearInterval(this.runningTimerText);
      this._timeEntriesService.runningTimerEntry(this.workspaceId).subscribe((res) => {
        let runningData = res.data;
        if(!!runningData && !!runningData?.id) {
         this.timerEntryId = res.data.id;
         this.selectedTaskName = res.data.description;
         var date = new Date(res.data.starts_at);
         const runningTimerText = date.getTime();
         this.workspaceTimerText = DateTime.utc().valueOf() - runningTimerText;

         this.runningTimerText = setInterval(() => {
           this.workspaceTimerText = DateTime.utc().valueOf() - runningTimerText;
           this.countDownTime = this._commonService.getTimeInHHMMSS(
             this.workspaceTimerText / 1000
           );
         }, 1000);

         this.countDownTime = this._commonService.getTimeInHHMMSS(
           this.workspaceTimerText / 1000
         );
         this.runningData = res.data;
         this.isStartTimerLoading = !!this.runningData?.id;
        } else{
         this.isStartTimerLoading = false;
        }
      })
    }
  }

  openmodel() {
    if (!this.fromDashboard) {
      let timerTab: any = document.getElementById('timerTab');
      timerTab.style.display = 'block';
    }
  }

  openswitchmodel() {
    if (!this.fromDashboard) {
      let switchTimer: any = document.getElementById('switch-timer');
      switchTimer.style.display = 'block';
    } else if (this.fromDashboard) {
      this.timerSwitchedOnDashboard = true;
    }
    this.timerDataLoaded = true;
  }
  closemodel() {
    let timerbtn: any = document.getElementById('dropdownMenuLink');
    if(timerbtn?.style){
      timerbtn.style.backgroundColor = "#23B7E5";
    }
    let timerTab: any = document.getElementById('timerTab');
    if (timerTab) timerTab.style.display = 'none';
    let switchTimer: any = document.getElementById('switch-timer');
    if (switchTimer) switchTimer.style.display = 'none';
    this.timerSwitchedOnDashboard = false;
  }

  initForms() {
    this.TimerForm = this._formBuilder.group({
      autoTimerForm: ['', Validators.required],
      billableHour: [true],
      manualHours: ['', Validators.required],
      manualMinutes: ['', [Validators.required, Validators.max(60),Validators.min(0),Validators.pattern("^[0-9]*$"),
    ]],
      switchTimerForm: ['', Validators.required],
      startDate: ['']
    }, {
      validator: this.customValidator('manualHours', 'manualMinutes'),
    });
  }

  customValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if(control.value || (matchingControl.value && matchingControl.value >0 && matchingControl.valid && !Number.isNaN(Number(matchingControl.value)))) {
        this.isValid = true;
      }
      else {
        this.isValid = false;
      }
    };
  }

  timer() {
    this.isActiveTab = true;
    this.manualTab = false;
  }
  manual() {
    this.isActiveTab = false;
    this.manualTab = true;
  }

  TimerCount() {
    clearInterval(this.timerText);
    clearInterval(this.runningTimerText);
    let date = DateTime.now().toISO() || '';
    const startUtcDate = DateTime.fromISO(date, { zone: 'utc' });
    const countDownDate = startUtcDate.valueOf();
    this.countDown = DateTime.utc().valueOf() - countDownDate;
    this.timerText = setInterval(() => {
      this.countDown = DateTime.utc().valueOf() - countDownDate;
      this.countDownTime = this._commonService.getTimeInHHMMSS(
        this.countDown / 1000
      );
    }, 1000);

    this.countDownTime = this._commonService.getTimeInHHMMSS(
      this.countDown / 1000
    );
  }

  startAutoTimer() {
    this.isBtnLoading = false;
    this.TimerCount();
    this.currentDate = new Date();
    let CurrentDateTime = DateTime.utc().toISO()

    if (this.fromDashboard && this.newUser) {
      this.TimerForm.get('autoTimerForm')?.patchValue(`Setting up and configuring Daybeam's time tracking system`);
      this.TimerForm.get('billableHour')?.patchValue(true);
      const currentTimeUTC = new Date().toISOString();
      const currentTime = new Date(currentTimeUTC);
      currentTime.setMinutes(currentTime.getMinutes() - 2);
      const newTimeUTC = currentTime.toISOString();
      CurrentDateTime = newTimeUTC;
    }

    let payload: any = {
      description: this.TimerForm.get('autoTimerForm')?.value,
      is_billable: this.TimerForm.get('billableHour')?.value,
      starts_at: CurrentDateTime
    };
    
    if (this.selectedTask?.groupId) {
      payload = { ...payload, workspace_group_id: this.selectedTask.groupId };
    }
    if (this.selectedTask?.taskId) {
      payload = { ...payload, workspace_task_id: this.selectedTask.taskId };
    }
    if (this.selectedTaskGroupId) {
      payload = { ...payload, workspace_group_id: this.selectedTaskGroupId };
    }
    this.startTimerApiRes = undefined;
    this._timeEntriesService
      .store(this.workspaceId, payload)
      .pipe(
        takeUntil(this.destroyed$),
        catchError((err) => {
          return throwError(err);
        })
      )
      .subscribe(
        (res) => {
          this.isStartTimerLoading = true;

          this.runningData = res.data;
          this.isStartTimerLoading = !!this.runningData?.id;
          this.timerEntryId = res.data.id;
          this.TimerForm.get('autoTimerForm')?.setValue('');
          this.TimerForm.get('switchTimerForm')?.setValue('');
          this.activeTimer = true;
          this.closemodel();
          this.startTimerApiRes = res?.data;
          this._toasterService.pop('success', `Timer Started!`);
          this.TimerForm.get('autoTimerForm')?.setValue('');
          this.starTimerFromWeb();
        },
        (err) => {
          this.isBtnLoading = true;
        }
      );
  }

  starTimerFromWeb() {
    window.postMessage(
      {
        type: 'FROM_DAYBEAM_WEB_START_TIMER',
        data: {
          workspace: {
            id: this.workspaceId,
          },
          timeEntry: this.startTimerApiRes,
        },
      },
      '*'
    );
  }

  stopTimerFromWeb() {
    window.postMessage(
      {
        type: 'FROM_DAYBEAM_WEB_STOP_TIMER',
        data: {
          workspace: {
            id: this.workspaceId,
          },
          timeEntry: this.startTimerApiRes,
        },
      },
      '*'
    );
  }

  onDateModalSelection(){
    this.bsValue = new Date();
    if (!!this.TimerForm.value.startDate) {
      setTimeout(() => {
        delete this.datePickerConfig['customTodayClass'];
        document
        .getElementsByClassName('custom-today-class')[0]
        ?.classList.remove('custom-today-class');
      }, 0);
    }
  }

  startDateChangeEvent(dateChangeEvent: Date){
    if (!this.TimerForm.value.startDate) {
      Object.assign(this.datePickerConfig, {
        customTodayClass: 'custom-today-class',
      });
    } else {
      delete this.datePickerConfig['customTodayClass'];
      document
        .getElementsByClassName('custom-today-class')[0]
        ?.classList.remove('custom-today-class');
    }

  }

  startManualTimer() {
    this.isManualBtnLoading = false;

    if(!this.TimerForm.get('startDate')?.value){
      this.currentTime = DateTime.utc();
      this.endTime = this.currentTime.plus({
        hours: Number(this.TimerForm.value.manualHours),
        minutes: Number(this.TimerForm.value.manualMinutes),
      });
    }

    if(!!this.TimerForm.get('startDate')?.value){
    this.startDateTime = new Date(this.TimerForm.get('startDate')?.value);

    if((Number(this.startDateTime.getHours()) !== new Date().getHours()) || Number(this.startDateTime.getMinutes()) !== new Date().getMinutes()){
      let hours = new Date().getHours()
      let minutes = new Date().getMinutes()
      this.startDateTime.setHours(hours);
      this.startDateTime.setMinutes(minutes);
    }

    let endTimeDate = moment.utc(this.startDateTime)
    this.endDateTime = endTimeDate.add({
      hours: Number(this.TimerForm.value.manualHours),
      minutes: Number(this.TimerForm.value.manualMinutes),
    });
  }

    let payload: any = {
      description: this.TimerForm.get('autoTimerForm')?.value,
      is_billable: this.TimerForm.get('billableHour')?.value
    };


    if(!!this.TimerForm.get('startDate')?.value){
      payload = { ...payload, starts_at: this.startDateTime, ends_at: this.endDateTime }
    } else {
      payload = { ...payload, starts_at: this.currentTime.toISO(), ends_at: this.endTime.toISO() }
    }

    if (this.selectedTask?.groupId) {
      payload = {
        ...payload,
        workspace_group_id: this.selectedTask?.groupId,
      };
    }
    if (this.selectedTask?.taskId) {
      payload = { ...payload, workspace_task_id: this.selectedTask.taskId };
    }
    if (this.selectedTaskGroupId) {
      payload = { ...payload, workspace_group_id: this.selectedTaskGroupId };
    }
    this.startTimerApiRes = undefined;
    this._timeEntriesService
      .store(this.workspaceId, payload)
      .pipe(
        takeUntil(this.destroyed$),
        catchError((err) => {
          return throwError(err);
        })
      )
      .subscribe((res) => {
        if (!this?.selectedTask?.taskId) {
          this._timerSharedService.addedNewTask.next(
            this.TimerForm.get('autoTimerForm')?.value
          );
        }



    if(!(this.totalTransactionTime > 0)) {
      this._workspaceService.startedTimer.next(res?.data?.id);
    }
    if(this.router.url !== '/tasks/waiting-for-approval') {
      this._timeEntriesService.updateTimeEntry.next(
        res?.data
      );
    }

        this.TimerForm.get('autoTimerForm')?.setValue('');
        this.TimerForm.get('switchTimerForm')?.setValue('');
        this.TimerForm.get('startDate')?.setValue('')
        this.startTimerApiRes = res?.data;
        this.closemodel();
        this.isBtnLoading = false;
        this._toasterService.pop('success', 'Manual Timer Added');
        this.setupTaskStopped.emit();
      },
      (err) => {
        this.isManualBtnLoading = true;
      }
      );
  }

  closeTimerDropdown() {
    this.closemodel();
    this.isBtnLoading = false;
    this.isManualBtnLoading = false;
    this.TimerForm.get('autoTimerForm')?.setValue('');
    this.TimerForm.get('switchTimerForm')?.setValue('');
    this.TimerForm.get('startDate')?.setValue('');
  }

  StopTimer() {
    this.isStopTimerLoading = true;
    let payload = {
      ends_at: DateTime.utc().toISO(),
    };
    let id = this.timerEntryId;
    this._timeEntriesService
      .stopTimerEntry(this.workspaceId, id, payload)
      .subscribe(
        (res) => {
          clearInterval(this.timerText);
          this.countDownTime = 0;
          this.isStartTimerLoading = false;
          this.closemodel();
          if(!(this.totalTransactionTime > 0)) {
            this._workspaceService.startedTimer.next(payload.ends_at);
          }
          this._timeEntriesService.updateTimeEntry.next(payload.ends_at);
          this.isBtnLoading = false;
          this.showSwitchTimer = false;
          this.isStopTimerLoading = false;
          this.TimerForm.get('autoTimerForm')?.setValue('');
          this.TimerForm.get('switchTimerForm')?.setValue('');
          this._toasterService.pop(
            'success',
            'Timer Stopped'
          );
          this.stopTimerFromWeb();
          this.timerDataLoaded = true;
          this.newUser = false;
          this.isPopOverOpen = false;
          this.setupTaskStopped.emit();
        },
        (err) => {
          this.closemodel();
          this.timerDataLoaded = true;
          this.isStopTimerLoading = false;
          if(err.error.message === "ends_at.not_runnable"){
            this.isStartTimerLoading = false;
          }
        }
      );
  }

  switchTask() {
    this.TimerForm.get('autoTimerForm')?.setValue('');
    this.TimerForm.get('switchTimerForm')?.setValue('');
    this.showSwitchTimer = true;
    this.isSwitchTaskLoading = true;
    this.openswitchmodel();
  }

  SwitchTaskTimer() {
    this.isSwitchBtnLoading = false;
    let date = {
      ends_at: new Date().toISOString(),
    };
    let id = this.timerEntryId;
    this._timeEntriesService
      .stopTimerEntry(this.workspaceId, id, date)
      .subscribe((res) => {
        clearInterval(this.timerText);
        this._timeEntriesService.updateTimeEntry.next(date.ends_at);
        this.countDownTime = 0;
        let payload: any = {
          description: this.TimerForm.get('switchTimerForm')?.value,
          is_billable: this.TimerForm.get('billableHour')?.value,
          starts_at: new Date()
        };
        this.stopTimerFromWeb();
        if (this.selectedTaskGroupId) {
          payload = {
            ...payload,
            workspace_group_id: this.selectedTaskGroupId,
          };
        }

        if (this.selectedTask?.taskId) {
      payload = { ...payload, workspace_task_id: this.selectedTask.taskId };
      }

        if (this.selectedSwitchTask?.groupId) {
          payload = {
            ...payload,
            workspace_group_id: this.selectedSwitchTask.groupId,
          };
        }

        this.TimerCount();
        this.startTimerApiRes = undefined;
        this._timeEntriesService
          .store(this.workspaceId, payload)
          .subscribe((res) => {
            if (!this?.selectedTask?.taskId) {
              this._timerSharedService.addedNewTask.next(
                this.TimerForm.get('autoTimerForm')?.value
              );
            }
            if(this.router.url !== '/tasks/waiting-for-approval') {
              this._timeEntriesService.updateTimeEntry.next(
                res?.data
              );
            }
            this._toasterService.pop('success', 'Timer Updated');
            this.TimerForm.get('switchTimerForm')?.setValue('');
            this.TimerForm.get('autoTimerForm')?.setValue('');
            this.showSwitchTimer = false;
            this.closemodel();
            this.timerEntryId = res.data.id;
            this.startTimerApiRes = res?.data;
            this.starTimerFromWeb();
            this.setupTaskStopped.emit();
            this.newUser = false;
            this.isPopOverOpen = false;
          },
          (err) => {
            this.isSwitchBtnLoading = true;
          }
          );
      });
  }

  changeTask(selectedTask) {
    this.selectedTask = selectedTask;
    this.selectedTaskName = selectedTask?.title;
    this.isModalLoading = false;
    this.TimerForm.get('autoTimerForm')?.patchValue(selectedTask.title);
    this.isBtnLoading = true;
    this.isManualBtnLoading = true;
    this.autoTimerTextValue = selectedTask;
    this.selectedTaskGroupId = null;
  }

  addNewTask(selectedNewTask, type) {
    this.selectedTask = undefined;
    this.selectedTaskName = selectedNewTask;
    if (type == 'auto') {
      this.TimerForm.get('autoTimerForm')?.patchValue(selectedNewTask);
      this.isBtnLoading = true;
      this.isModalLoading = false;
      this.isManualBtnLoading = true;
    } else {
      this.TimerForm.get('switchTimerForm')?.patchValue(selectedNewTask);

      this.isSwitchBtnLoading = true;
      this.isSwitchmodalLoading = false;
    }
    this.selectedTaskGroupId = null;
  }

  addNewTaskWithGroup(selectedNewTask, group?, type?) {
    this.selectedTask = undefined;
    this.selectedTaskName = selectedNewTask;
    if (type == 'auto') {
      this.TimerForm.get('autoTimerForm')?.patchValue(selectedNewTask);
      this.isBtnLoading = true;
      this.isModalLoading = false;
      this.isManualBtnLoading = true;
    } else {
      this.TimerForm.get('switchTimerForm')?.patchValue(selectedNewTask);
      this.isSwitchBtnLoading = true;
      this.isSwitchmodalLoading = false;
    }

    if (!!group) {
      this.selectedTaskGroupId = group.id;
    }
  }

  changeSwitchTask(selectedSwitchTask) {
    this.selectedTaskName = selectedSwitchTask;
    if (selectedSwitchTask.title === this.autoTimerTextValue?.title) {
      this.isSwitchBtnLoading = false;
      this.TimerForm.get('switchTimerForm')?.patchValue(
        selectedSwitchTask.title
      );
      this.isSwitchmodalLoading = false;
      return;
    }
    this.selectedSwitchTask = selectedSwitchTask;
    this.isSwitchmodalLoading = false;
    this.isSwitchBtnLoading = true;
    this.TimerForm.get('switchTimerForm')?.patchValue(selectedSwitchTask.title);
  }

  startTimerGetProject(searchValue, type) {
    if (searchValue && !this.groupLoaded) {
      this.groupLoaded = true;
      this._groupService.getUserGroupList(this.workspaceId).subscribe(
        (res) => {
          this.userGroupListData = res.data;
          this.userGroupList = [...this.userGroupListData];
        },
        (err) => {
          this.groupLoaded = false;
        }
      );
    }

    if (!searchValue) {
      this.isBtnLoading = false;
      this.isManualBtnLoading = false;
      this.isSwitchBtnLoading = false;
    }
    else {
      this.userGroupList = [...this.userGroupListData];
    }

    if (this.searchDataSubscribe) {
      this.searchDataSubscribe.unsubscribe();
    }
    this.searchValue = searchValue;
    this.searchLength = this.searchValue.length;

    if (type == 'auto') {
      this.isModalLoading = true;
      this.isSwitchmodalLoading = false;
    } else {
      this.isModalLoading = false;
      this.isManualModalLoading = false;
      this.isSwitchmodalLoading = true;
    }

    let params = {};
    params['query'] = searchValue;

    if (params['query'] != '') {
      this.searchDataSubscribe = this._timeEntriesService
        .SearchWorkspaceTimeEntry(this.workspaceId, params)
        .pipe(takeUntil(this.destroyed$), debounceTime(2000))
        .subscribe(
          (res) => {
            this.searchDataSubscribe = null;
            this.searchData = res.data.map((val) => {
              return {
                title: val.title,
                groupName: val.group.name,
                groupId: val.group.id,
                taskId: val.id,
              };
            });
            let groupId = res.data.map((val)=> val.group.id);
            this.groupExist = groupId.filter(element => !!element)?.length == groupId?.length && groupId?.length ;
            this.taskExist = this.searchData.find(
              (val) => val.title.toLowerCase() == (this.TimerForm.get('autoTimerForm')?.value.toLowerCase() || this.TimerForm.get('switchTimerForm')?.value.toLowerCase())
            )
            let ids: any = [];
            if (
              this.searchData.find(
                (val) => val.title.toLowerCase() == (this.TimerForm.get('autoTimerForm')?.value.toLowerCase() || this.TimerForm.get('switchTimerForm')?.value.toLowerCase())
              )
            ) {
              this.searchData.filter((val, ind) => {
                if (
                  this.userGroupList.find((group) => group.id === val.groupId)
                ) {
                  ids.push(val.groupId);
                }
              });
              ids = ids.filter((item, index) => ids.indexOf(item) === index);
              ids.forEach((id) => {
                let ind: any = this.userGroupList
                  .map((val) => val.id)
                  .indexOf(id);
                if (id != -1 && !!id) {
                  this.userGroupList.splice(ind, 1);
                }
              });
            }
          },
          (err) => {
            this.searchDataSubscribe = null;
          }
        );
    }
  }

  stopNewUserTask() {
    if (this.fromDashboard) {
      this.newUserTaskStopped = true;
      this.timerDataLoaded = false;
    }
  }

  switchToManual() {
    this.isActiveTab = !this.isActiveTab;
    this.manualTab = !this.manualTab;
    this.switchedToManualOnDashboard = !this.switchedToManualOnDashboard;
  }

  stopAndUpdateTasksOnDashboard() {
    this.setupTaskStopped.emit();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    clearInterval(this.timerText);
    clearInterval(this.runningTimerText);
  }
}
