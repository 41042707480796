import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../common/constant/ApiConstants';
import { CustomHttpService } from './custom.http.service';

@Injectable()
export class SignupService {
  constructor(private _customHttpService: CustomHttpService) {}

  register(oData: string): Observable<any> {
    return this._customHttpService.post(
      API_CONSTANTS.API_ENDPOINT_URL + '/api/oauth/register',
      oData
    );
  }

  registerviaToken(oData: any, token: any) {
    return this._customHttpService.post(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspace-invite-users/' +
        token +
        '/register',
      oData
    );
  }

  resetPasswordUser(oData: string): Observable<any> {
    return this._customHttpService.post(
      API_CONSTANTS.API_ENDPOINT_URL + '/api/password/email',
      oData
    );
  }

  storeResetToken(oData: string, token: any): Observable<any> {
    return this._customHttpService.post(
      API_CONSTANTS.API_ENDPOINT_URL + '/api/password/reset/' + token,
      oData
    );
  }

  checkResetToken(token: string): Observable<any> {
    return this._customHttpService.get(
      API_CONSTANTS.API_ENDPOINT_URL + '/api/password/reset/' + token
    );
  }
}
