import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  timeout;
  constructor() {}

  show() {
    var body = document.querySelector('body');

    if (body) {
      body.style.opacity = '0.4';
    }
    var counter = 0;
    var preloader = document.querySelector('.manual-preloader-hidden');
    if (preloader) {
      preloader.classList.value = '';
      preloader.classList.add('manual-preloader');
    }
    var progressBar = document.querySelector<HTMLElement>(
      '.manual-preloader-progress-bar'
    );

    if (progressBar) {
      progressBar.style.display = 'block';
    }

    if (!preloader) return;

    this.timeout = setTimeout(startCounter, 20);

    function startCounter() {
      var remaining = 100 - counter;
      counter = counter + 0.015 * Math.pow(1 - Math.sqrt(remaining), 2);

      if (progressBar) {
        progressBar.style.width = Math.round(counter) + '%';
      }
    }
    this.timeout = setTimeout(startCounter, 20);
  }

  hide() {
    clearTimeout(this.timeout);
    var preloader = document.querySelector('.manual-preloader');
    var progressBar = document.querySelector<HTMLElement>(
      '.manual-preloader-progress-bar'
    );
    var body = document.querySelector('body');

    if (progressBar) progressBar.style.width = '100%';

    setTimeout(function () {
      removePreloader();
    }, 300);

    function removePreloader() {
      setTimeout(() => {
        if (preloader) {
          preloader.className = 'manual-preloader-hidden';
        }

        if (body) {
          body.style.opacity = '1';
        }
      }, 100);
    }
  }
}
