import { EventEmitter, Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  [x: string]: any;

  constructor(private _customHttpService: CustomHttpService) {}

  changePassword(oData: string): Observable<any> {
    return this._customHttpService.post(
      API_CONSTANTS.API_ENDPOINT_URL + '/api/oauth/change-password',
      oData
    );
  }

  getProfileInformation(params?): Observable<any> {
    return this._customHttpService.get(
      API_CONSTANTS.API_ENDPOINT_URL + '/api/users/' + params.toString()
    );
  }

  updateUserData(oData: any, id): Observable<any> {
    return this._customHttpService.put(
      API_CONSTANTS.API_ENDPOINT_URL + '/api/users/' + id,
      oData
    );
  }

  saveProfile(oData: any): Observable<any> {
    return this._customHttpService.post(
      API_CONSTANTS.API_ENDPOINT_URL + '/api/files',
      oData
    );
  }
  saveFile(oData: any): Observable<any> {
    return this._customHttpService.postFormData(
      API_CONSTANTS.API_ENDPOINT_URL + '/api/files',
      oData
    );
  }
}
