import { NgxSelectModule } from 'ngx-select-ex';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { LayoutModule } from 'src/app/layout/layout.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ToasterModule } from 'angular2-toaster';
import { PasswordResetTokenComponent } from './password-reset-token/password-reset-token.component';
import { CreateWorkspaceComponent } from './create-workspace/create-workspace.component';

@NgModule({
  imports: [SharedModule, NgxSelectModule, LayoutModule, ToasterModule],
  declarations: [
    LoginComponent,
    SignupComponent,
    ResetPasswordComponent,
    PasswordResetTokenComponent,
    CreateWorkspaceComponent,
  ],
  exports: [
    RouterModule,
    LoginComponent,
    SignupComponent,
    ResetPasswordComponent,
    PasswordResetTokenComponent,
    CreateWorkspaceComponent,
  ],
})
export class PagesModule {}
