const Dashboard = {
  text: 'Dashboard',
  icon: 'fab fa-telegram-plane',
  link: '/dashboard',
};

const Users = {
  text: 'Users',
  icon: 'fas fa-user-friends',
  link: '/users',
  link1: '/users/pending-invitations'
};

const Reports = {
  text: 'Reports',
  icon: 'fas fa-search-dollar',
  link: '/reports/tasks',
  link1: '/reports/users',
  link2: '/reports/transactions',

};

const Advanced = {
  text: 'Advanced',
  icon: 'fas fa-cogs',
  link: '/tasks/approved',
  link1: '/groups',
  link2: '/tags',
  link3: '/tasks/waiting-for-approval',
};

const Pages = {
  text: 'Pages',
  link: '/pages',
  icon: 'icon-doc',
  submenu: [
    {
      text: 'Login',
      link: '/login',
    },
    {
      text: 'Register',
      link: '/register',
    },
  ],
};

export const menu = [Dashboard, Users, Reports, Advanced];
