import {
  Component,
  OnInit,
  ViewChild,
  Injector,
  Input,
  HostListener,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import { LocalStorageService } from 'src/app/services/localstorage.service';
import { ApiService } from 'src/app/services/common/api.service';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { PendingInvitationService } from 'src/app/services/pending-invitation.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/services/user/user.service';
import { TimeEntriesService } from 'src/app/services/time-entries/time-entries.service';
import { DateTime } from 'ts-luxon';
import { NgSelectComponent } from '@ng-select/ng-select';
import { takeUntil, filter } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { DaybeamUtil } from 'src/app/common/DaybeamUtil';
import { LoginService } from 'src/app/services/login.service';
import { ShareDataService } from 'src/app/services/shared-data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  selectedWorkspaceName: any;

  isVisible: boolean = false;
  navCollapsed = true; // for horizontal layout
  menuItems: Array<any> = []; // for horizontal layout
  router!: Router;
  @Input() onlyLogo = false;
  profileImageUrl: string = '';
  timerTextValue: any;
  countDownTime: any;

  WorkspaceList: any;
  userRoleId: any;

  data: any;
  unsubscribe$ = new ReplaySubject<any>(10);
  isFeedspaceInitialized = DaybeamUtil.isFeedspaceInitialized;
  isLoaded = true;
  selectedItem: any;
  isNavSearchVisible: boolean = false;
  primaryUserRoleId = DaybeamUtil.PrimaryUser;
  @ViewChild('fsbutton', { static: true }) fsbutton; // the fullscreen button
  loggedInUser = this._localStorageService.getCurrentUser();
  workspaceId: any;
  workspaceGroup!: FormGroup;
  mDevice: boolean = false;
  runningData: any;
  workspaceTimerText: any;
  isupdatedWorkspace: any;
  WorkspaceListData: any;
  currentUser: any;
  defaultWorkspace: any;
  updatedWorkspace: any;
  workspaceData: any;
  windowObj: any = window;
  isOnDashboard: boolean = false;
  routerSubscription;

  @HostListener('document:click', ['$event'])
  clickOutside() {
    document.body.addEventListener('click', function (event) {
    let containingElement: any = document.querySelector('#earningTimer');
    let TimerBtn = document.getElementById('dropdownMenuLink');
    if(TimerBtn){
      TimerBtn.style.backgroundColor = "31afef";
    }
      let eventData: any = event;
      if (
        containingElement?.contains(event.target) ||
        eventData.target.alt == 'toggle' ||
        eventData.target.name == 'test' ||
        eventData.target.classList.value.includes('searched-task') ||
        eventData.target.classList.value.includes('task-timer') ||
        eventData.target.classList.value.includes('group-timer') ||
        eventData.target.classList.value.includes('search-task-group') ||
        eventData.target.classList.value.includes('addNewSearchList') ||
        eventData.target.classList.value.includes('search-task-wrapper') ||
        eventData.target.classList.value.includes('start-tracking-task-btn') ||
        eventData.target.classList.value.includes('plus-icon') ||
        eventData.target.classList.value.includes('standard-add-btn-text') ||
        eventData.target.classList.value.includes('start-tracking-wrapper') ||
        eventData.target.classList.value.includes('bs-media-container') ||
        eventData.target.classList.value.includes('bs-datepicker-body') ||
        eventData.target.classList.value.includes('is-highlighted')
      ) {
      } else {
        let timerMain: any = document.getElementById('timerTab');
        let timerbtn: any = document.getElementById('dropdownMenuLink');
        if (timerMain?.style) {
          timerMain.style.display = 'none';
          if(timerbtn?.style){
            timerbtn.style.backgroundColor = "#31afef"
          }
          let timerClick: any = document.getElementById('timer-click');
        }
        let timerMain2: any = document.getElementById('switch-timer');
        let timerbtn1: any = document.getElementById('dropdownMenuLink');
        if (timerMain2?.style) {
          timerMain2.style.display = 'none';
          if(timerbtn1?.style){
            timerbtn1.style.backgroundColor = "#31afef"
          }
          let timerClick: any = document.getElementById('timer-click');
        }
      }
    });
  }
  constructor(
    private fb: FormBuilder,
    public menu: MenuService,
    public userblockService: UserblockService,
    public settings: SettingsService,
    public injector: Injector,
    private route: Router,
    private _pendingInvitationService: PendingInvitationService,
    private _workspaceService: WorkspaceService,
    public _localStorageService: LocalStorageService,
    public _apiService: ApiService,
    public _userService: UserService,
    public _timeEntriesService: TimeEntriesService,
    public _loginService: LoginService,
    private _router: Router,
    public _sharedDataService: ShareDataService
  ) {
    // show only a few items on demo
    this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
    this.profileImageUrl = this._localStorageService.getProfileImageURL();
    this.routerSubscription = _router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd ) {
        if (event.url == '/dashboard') {
          this.isOnDashboard = false;
        } else {
          this.isOnDashboard = true;
        }
      } else {
        this.isOnDashboard = true;
      }
    });
    this._sharedDataService.onDashboardData.subscribe(
      data => {
        if (data == false) {
          this.isOnDashboard = false;
        } else if (data == true) {
          this.isOnDashboard = true;
        }
      }
    )
    
  }


	@ViewChild('feedbackBtnDesktop') set fbDesktop(content: ElementRef) {
		if (content && !this.isFeedspaceInitialized) {
      this.windowObj.feedspace.init();
      this.isFeedspaceInitialized = true;
		}
	}

  checkDevice() {
    const details = navigator.userAgent;

    /* Creating a regular expression
        containing some mobile devices keywords
        to search it in details string*/
    const regexp = /android|iphone|kindle|ipad/i;

    /* Using test() method to search regexp in details
        it returns boolean value*/
    const isMobileDevice = regexp.test(details);

    if (isMobileDevice) {
      this.mDevice = true;
    }
  }

  ngOnInit() {
    this._loginService.currentUser.subscribe((val) => {
      this.currentUser = val
    })
    this.checkDevice();
    const params = {};
    const currentUser = this._localStorageService.getCurrentUser();
    let include = ['role_id', 'users_count']
    let token = this._localStorageService.getItem('invite_token');

    this._workspaceService.workspaceArchived
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((val) => {
      this._workspaceService.getWorkspace(params,include).subscribe((res) => {
        this.WorkspaceList = res.data;
      });
      if (!val) {
        this.workspaceId = null;
        this.selectedWorkspaceName = '';
        this.workspaceGroup.controls.workspaceName.setValue('');
        this.route.navigate(['/create-workspace']);
        this.workspaceId = null;
      }

      if (this._workspaceService.workspaceId.value?.id != val?.id) {
        if(!!val?.role_id){
          localStorage.setItem('role_Id', val?.role_id);
          this.userRoleId = val?.role_id;
        }
        if (!val?.archived_at) {
          this.workspaceId = val?.id;
          this.selectedWorkspaceName = val?.name;
          this.workspaceGroup.controls.workspaceName.setValue(val?.name);
        }
        let oCurrentUser = this._localStorageService.getCurrentUser();
        oCurrentUser['default_workspace_id'] = val?.id;
        this._localStorageService.setCurrentUser(oCurrentUser);
        this._localStorageService.setWorkspace(val);
        this._workspaceService.workspaceId.next(val);
        this._localStorageService.setWorkspace(val);
      }
    });

    this._workspaceService.workspaceUpdated
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((val) => {
      this._workspaceService.getWorkspace(params, include).subscribe((res) => {
        let oCurrentUser = this._localStorageService.getCurrentUser();
        if (
          !res.data.find(
            (val) => val.id === oCurrentUser['default_workspace_id']
          )
        ) {
          if(!!res.data[0]?.role_id){
            localStorage.setItem('role_Id', res.data[0]?.role_id);
            this.userRoleId = res.data[0]?.role_id;
          }
            this.workspaceId = res.data[0]?.id;
            this.selectedWorkspaceName = res.data[0]?.name;
            this.workspaceGroup.controls.workspaceName.setValue(
              res.data[0]?.name
            );
           this._workspaceService.workspaceId.next(res.data[0]);
          this._localStorageService.setWorkspace(res.data[0]);
          const id = currentUser['id'];
          const param = {
            default_workspace_id: res.data[0]?.id,
          };
          this._userService.updateUserData(param, id).subscribe((data) => {
            let oCurrentUser = this._localStorageService.getCurrentUser();
            oCurrentUser['default_workspace_id'] =
              data.data.default_workspace_id;
            this._localStorageService.setCurrentUser(oCurrentUser);
          });
        } else if (
          !res.data.find(
            (val) => val.id === oCurrentUser['default_workspace_id']
          )
        ) {
          let updateWorkspace = res.data[0];
          if(!!updateWorkspace.role_id){
            localStorage.setItem('role_Id', updateWorkspace?.role_id);
            this.userRoleId = updateWorkspace?.role_id;
          }
            this.workspaceId = updateWorkspace.id;
            this.selectedWorkspaceName = updateWorkspace.name;
            this.workspaceGroup.controls.workspaceName.setValue(
              updateWorkspace.name
            );
           this._workspaceService.workspaceId.next(updateWorkspace);
          this._localStorageService.setWorkspace(updateWorkspace);
          const id = currentUser['id'];
          const param = {
            default_workspace_id: updateWorkspace.id,
          };
          this._userService.updateUserData(param, id).subscribe((data) => {
            let oCurrentUser = this._localStorageService.getCurrentUser();
            oCurrentUser['default_workspace_id'] =
              data.data?.default_workspace_id;
            this._localStorageService.setCurrentUser(oCurrentUser);
          });
        } else {
          this.workspaceId = oCurrentUser['default_workspace_id'];
        }
        this.WorkspaceList = res.data;
      });
    });

    this._workspaceService.seeWorkspace
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((val) => {
        if (!val?.created_at) {
          let oCurrentUser = this._localStorageService.getCurrentUser();
          let workspace = this.WorkspaceList.find(
            (val) => val.id === oCurrentUser['default_workspace_id']
          );
          this._workspaceService.workspaceId.next(workspace);
          this._localStorageService.setWorkspace(workspace);
          if(!!workspace?.role_id){
            localStorage.setItem('role_Id', workspace?.role_id);
            this.userRoleId = workspace?.role_id;
          }
        }
      // this._localStorageService.setWorkspace(val);
      this.workspaceId = val?.id
      this.selectedWorkspaceName = val.name;
      this.workspaceGroup.controls.workspaceName.setValue(val.name);
    });

    this.workspaceGroup = this.fb.group({
      workspaceName: [''],
    });
    if (this._localStorageService.isUserLoggedIn() && !this.route.url.includes('login')) {
      this.isVisible = true;
      if(!this.currentUser) {
          this._apiService.getAuthUser().subscribe((data) => {
          this._localStorageService.setCurrentUser(data.data);
          this.defaultWorkspace = data?.data?.default_workspace_id;
          let workspaceData = this.workspaceData?.filter((val)=> val?.id == data?.data?.default_workspace_id)[0];
          if(!!workspaceData?.id)  this._localStorageService.setWorkspace(workspaceData);
          if(this.defaultWorkspace && this.workspaceData){
            let workspace = this.workspaceData?.filter((val)=> val?.id == this.defaultWorkspace)[0]
            if(!!workspace?.id)  this._localStorageService.setWorkspace(workspace);
              this.selectedWorkspaceName = workspace.name;
              this.workspaceId = workspace.id;
                this.workspaceGroup.controls.workspaceName.setValue(
                  workspace.name
                );
              }
    		    	this.setFeedspaceData(data.data);
          });
        }
      if (this.route.url == '/create-workspace' && !this._localStorageService.getWorkspace() && !token) {
        return;
      }
        this._workspaceService.getWorkspace(params, include).subscribe((res) => {
          if(this.defaultWorkspace){
            this.workspaceData = res.data;
            let workspace = this.workspaceData.filter((val)=> val?.id == this.defaultWorkspace)[0]
              this._localStorageService.setWorkspace(workspace);
              this.selectedWorkspaceName = workspace.name;
              this.workspaceId = workspace.id;
                this.workspaceGroup.controls.workspaceName.setValue(
                  workspace.name
                );
              }
          if (
            res.data.length ===
              0 &&
            !!token
          ) {
            this._pendingInvitationService
              .getPendingInvitation()
              .subscribe((res) => {
                if(res.data[0]?.workspace_id) {
                const payload = {};
                this._pendingInvitationService
                  .joinWorkspace(
                    res.data[0]?.workspace_id,
                    res.data[0]?.token,
                    payload
                  )
                  .subscribe((res) => {
                    this._localStorageService.removeItem('invite_token');
                    this._workspaceService
                      .getWorkspace(params, include)
                      .subscribe((res) => {
                        if(!!res){
                          localStorage.setItem(
                            'role_Id',
                            res.data.map((id) => id.role_id)
                          );
                          this.userRoleId = res.data[0]?.role_id;
                        }
                          this.workspaceId = res.data[0].id;
                          this.selectedWorkspaceName = res.data[0].name;
                          this.workspaceGroup.controls.workspaceName.setValue(
                            res.data[0].name
                          );
                        const id = currentUser['id'];
                        const param = {
                          default_workspace_id: res.data[0].id,
                        };
                        this._userService
                          .updateUserData(param, id)
                          .subscribe((data) => {
                            let oCurrentUser =
                              this._localStorageService.getCurrentUser();
                            oCurrentUser['default_workspace_id'] =
                              data.data.default_workspace_id;
                            this._localStorageService.setCurrentUser(
                              oCurrentUser
                            );
                          });

                        if (
                          this._workspaceService.workspaceId.value?.id !=
                          res.data[0].id
                        ) {
                          this._workspaceService.workspaceId.next(res.data[0]);
                          this._localStorageService.setWorkspace(res.data[0]);
                        }
                        this.WorkspaceList = res.data.filter(
                          (val) => !val?.archived_at
                        );
                      });
                  });
                }
              });
          } else if (!!token) {
            let workspace = res.data.find(
              (val) => val.id === currentUser['default_workspace_id']
            )
              ? res.data.find(
                  (val) => val.id === currentUser['default_workspace_id']
                )
              : res.data[0];
            if (!workspace?.archived_at) {
              this.workspaceId = workspace?.id;
              this.selectedWorkspaceName = workspace.name;
              this.workspaceGroup.controls.workspaceName.setValue(
                workspace.name
              );
            }
            if(!!workspace.role_id){
              localStorage.setItem('role_Id', workspace.role_id);
              this.userRoleId = workspace?.role_id;
            }
            this._workspaceService.workspaceId.next(workspace);
            this._localStorageService.setWorkspace(workspace);
            this.router.navigate(['/workspaces/pending-invitations']);
            this._localStorageService.removeItem('invite_token');
          } else {
            let workspace: any = this._localStorageService.getWorkspace();
            if (workspace) {
              if (!workspace?.archived_at) {
                this.workspaceId = workspace.id;
                this.selectedWorkspaceName = workspace.name;
                this.workspaceGroup.controls.workspaceName.setValue(
                  workspace.name
                );
              }
              if(!!workspace.role_id){
                localStorage.setItem('role_Id', workspace.role_id);
                this.userRoleId = workspace?.role_id;
              }
              if (
                this._workspaceService.workspaceId.value?.id !== workspace?.id
              ) {
                 this._workspaceService.workspaceId.next(workspace);
                 this._localStorageService.setWorkspace(workspace);
              }
              if (
                res.data.filter((val) => val?.workspace?.archived_at).length ===
                res.data?.length
              ) {
                this.workspaceId = null;
                this.selectedWorkspaceName = '';
                this._workspaceService.workspaceId.next(workspace);
                this._localStorageService.setWorkspace(workspace);
                this.route.navigate(['/create-workspace']);
                this.workspaceGroup.controls.workspaceName.setValue('');
                return;
              }
            } else if (res.data?.length) {
              let workspace = res.data.find(
                (val) => val.id === currentUser['default_workspace_id']
              )
                ? res.data.find(
                    (val) => val.id === currentUser['default_workspace_id']
                  )
                : res.data[0];

              if (!workspace?.archived_at) {
                this.workspaceId = workspace?.id;
                this.selectedWorkspaceName = workspace.name;
                this.workspaceGroup.controls.workspaceName.setValue(
                  workspace.name
                );
              }
              if(!!workspace.role_id){
                localStorage.setItem('role_Id', workspace.role_id);
                this.userRoleId = workspace?.role_id;
              }
               this._workspaceService.workspaceId.next(workspace);
              this._localStorageService.setWorkspace(workspace);
              if (
                res.data.filter((val) => val?.workspace?.archived_at).length ===
                res.data?.length
              ) {
                this.route.navigate(['/create-workspace']);
              }
            } else {
              this.route.navigate(['/create-workspace']);
            }
            if (
              this._workspaceService.workspaceId.value?.id != this.workspaceId
            ) {
              if(!!workspace?.role_id){
                this.userRoleId = workspace?.role_id;
              }
               this._workspaceService.workspaceId.next(workspace);
              this._localStorageService.setWorkspace(workspace);
            }
            this.WorkspaceList = res.data;
            if (this.userRoleId == 3 && !res.data.length) {
              this.route.navigate(['/dashboard']);
            }
          }
        });
    } else {
      this.isVisible = false;
    }

    this.isNavSearchVisible = false;

    var ua = window.navigator.userAgent;
    if (ua.indexOf('MSIE ') > 0 || !!ua.match(/Trident.*rv\:11\./)) {
      // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none';
    }

    this.router = this.injector.get(Router);

    // Autoclose navbar on mobile when route change
    this.router.events.subscribe((val) => {
      // scroll view to top
      window.scrollTo(0, 0);
      // close collapse menu
      this.navCollapsed = true;
    });
    this.initSubscription();
  }

  initSubscription() {
    this.profileImageUrl = this._localStorageService.getProfileImageURL();

    this._localStorageService.profilePictureChanged
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((data) => {
      this.profileImageUrl = this._localStorageService.getProfileImageURL();
    });

    // 	this.loginBeanNameChanged =
    // 		this._commonService.loginBeanNameChanged.subscribe((data) => {});
  }

  setFeedspaceData(userData) {
		const data = {
			Id: userData.id,
			Name: userData.name,
      Email: userData.email,
      WorkspaceId: userData.default_workspace_id,
      WorkspaceName: this.selectedWorkspaceName
		};
		this.windowObj.feedspace.setUserDetails(data);
	}

  onChangeWorkspace(e) {
    const currentUser = this._localStorageService.getCurrentUser();
    const id = currentUser['id'];
    const param = {
      default_workspace_id: e.id,
    };
    this._userService.updateUserData(param, id).subscribe((data) => {
      let oCurrentUser = this._localStorageService.getCurrentUser();
      oCurrentUser['default_workspace_id'] = data.data.default_workspace_id;
      this._localStorageService.setCurrentUser(oCurrentUser);
    });

    this.isupdatedWorkspace = e?.id;

    // this._apiService.getAuthUser().subscribe((data) => {
    //   this._localStorageService.setCurrentUser(data.data);
    // });

    if (!e?.id) {
      let workspace: any = this._localStorageService.getWorkspace();
      this.selectedWorkspaceName = workspace.name;
      this.workspaceGroup.controls.workspaceName.setValue(workspace.name);
      this.route.navigate(['/workspace']);
      return;
    } else {
      if (this.WorkspaceList.length == -1) {
      }
      if(e?.role_id){
        this._localStorageService.setItem('role_Id', e.role_id);
      }
      if(e.role_id == DaybeamUtil.standardUser) {
        let routes = ['/reports/transactions','/profile/account','/profile/change-password','/profile/extension-settings','/workspaces','/workspaces/pending-invitations']
        if(!routes.includes(this.router.url)){
          this._router.navigate(['/dashboard']);
        }
      }
      this._localStorageService.setWorkspace(e);
      this._workspaceService.workspaceId.next(e);
    }
  }

  closeSelect(select: NgSelectComponent) {
    select.close();
  }

  toggleUserBlock(event) {
    event.preventDefault();
    this.userblockService.toggleVisibility();
  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
    this.isNavSearchVisible = stat;
  }

  onClick() {
    this.route.navigate(['dashboard']);
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleOffsidebar() {
    this.settings.toggleLayoutSetting('offsidebarOpen');
  }

  toggleCollapsedSideabar() {
    this.settings.toggleLayoutSetting('isCollapsed');
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
    this.routerSubscription.unsubscribe();
	}
}
