<div class="wrapper">
  <app-header class="topnavbar-wrapper"></app-header>
  <div class="create-workspace-header">
    <h1 class="welcome-user">
      <span>{{ "labels.welcome" | translate }}&nbsp;{{ userName }}!</span>
    </h1>
    <p class="add-workspace-text">Please add your first Workspace:</p>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="container create-workspace-body">
        <div class="justify-content-center create-workspace-wrapper">
          <div class="form-group pb-1">
            <label class="label-required">{{
              "labels.workspace_name" | translate
            }}</label>
            <span class="text-danger pl-1">*</span>
            <em
            class="fas fa-info-circle info-icon text-primary clickable ml-1"
            [tooltip]="'Workspace will serve as the central hub for your time tracking data'">
            </em>
            <div class="input-group">
              <input
                type="text"
                id="workspace"
                [formControl] ="workSpaceName"
                placeholder="Workspace Name"
                class="form-control"
                autocomplete="off"
                (keydown.enter)="onWorkspaceCreate()"
              />
            </div>
          </div>
          <div>
            <button
              [disabled]="!workSpaceName || CreateWorkspaceLoading"
              class="btn edit-workspace-text create-workspace-btn text-white"
              (click)="onWorkspaceCreate()"
            >
              <div class="d-flex align-items-center">
                <em class="far fa-check-circle checkbox-icon text-white"></em>
                <p class="mb-0 login-btn-text text-white">
                  {{ "labels.create_workspace" | translate }}
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
