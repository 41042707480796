<div class="wrapper">
  <div class="row">
    <div class="block-center mt-4 col-9 col-sm-6 col-md-6 col-lg-6 col-xl-3">
      <!-- START card-->
      <div class="text-center">
        <a href="#" target="_blank">
          <img
            alt="Logo"
            class="block-center mb-3 width-50"
            src="assets/img/daybeam-logo.png"
          />
        </a>
      </div>
      <div class="card card-flat">
        <div class="card-body">
          <p class="text-center py-2 text-black">
            {{ "labels.new_password" | translate }}
          </p>
          <div *ngIf="oError">
            <alert *ngIf="oError?.status" [dismissible]="true" type="danger">
              {{ oError.error.message }}
            </alert>
          </div>
          <form
            (submit)="submitForm($event)"
            *ngIf="valForm"
            [formGroup]="valForm"
            class="form-validate mb-3"
            name="recoverPasswordForm"
            novalidate=""
            role="form"
          >
            <div>
              <div class="form-group">
                <label class="text-muted mb-1">{{
                  "labels.new_password" | translate
                }}</label>
                <div class="input-group">
                  <input
                    class="form-control"
                    formControlName="password"
                    name="password"
                    placeholder="Password"
                    type="password"
                    autocomplete="off"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text bg-transparent">
                      <em class="fa fa-lock text-muted"></em>
                    </span>
                  </div>
                </div>
                <div
                  *ngIf="
                    valForm.get('password')!.hasError('required') &&
                    (valForm.get('password')!.dirty ||
                      valForm.get('password')!.touched)
                  "
                  class="text-danger"
                >
                  {{ "errors.required" | translate }}
                </div>
                <div
                  *ngIf="
                    valForm.get('password')!.hasError('minlength') &&
                    (valForm.get('password')!.dirty ||
                      valForm.get('password')!.touched)
                  "
                  class="text-danger"
                >
                  {{ "errors.valid_min_characters" | translate }}
                </div>
                <div
                  *ngIf="
                    valForm.get('password')!.hasError('maxlength') &&
                    (valForm.get('password')!.dirty ||
                      valForm.get('password')!.touched)
                  "
                  class="text-danger"
                >
                  {{ "errors.valid_max_characters" | translate }}
                </div>
              </div>
              <div class="form-group">
                <label class="text-muted mb-1">
                  {{ "labels.new_password_confirmation" | translate }}
                </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    formControlName="confirm_password"
                    name="confirm_password"
                    placeholder="Password confirmation"
                    type="password"
                    autocomplete="off"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text bg-transparent">
                      <em class="fa fa-lock text-muted"></em>
                    </span>
                  </div>
                </div>
                <div
                  *ngIf="
                    valForm.get('confirm_password')!.hasError('required') &&
                    (valForm.get('confirm_password')!.dirty ||
                      valForm.get('confirm_password')!.touched)
                  "
                  class="text-danger"
                >
                  {{ "errors.required" | translate }}
                </div>
                <div
                  *ngIf="valForm.get('confirm_password')!.hasError('equalTo')"
                  class="text-danger"
                >
                  {{ "errors.valid_password" | translate }}
                </div>
              </div>
            </div>
            <button
              [disabled]="!valForm.valid || passwordResetTokenLoading"
              class="btn btn-block btn-primary mt-3"
              type="submit"
            >
              {{ "labels.change_password" | translate }}
            </button>
          </form>
          <div *ngIf="serverMessage" class="mt-3">
            <div *ngIf="serverStatusCode == 200">
              <div class="alert alert-success">
                {{ serverMessage }}
              </div>
            </div>
            <div *ngIf="serverStatusCode != 200">
              <div class="alert alert-danger">
                {{ serverMessage }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END card-->
      <div class="p-3 text-center text-black">
        <span>&copy;</span>
        <span>{{ _settingsService.getAppSetting("year") }}</span>
        <span class="mx-2">-</span>
        <span>
          {{ "labels.daybeam" | translate }}
        </span>
      </div>
    </div>
  </div>
</div>
