import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EarningTimerComponent } from './earning-timer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '../../shared.module';
@NgModule({
  declarations: [EarningTimerComponent],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
  ],
  exports: [EarningTimerComponent],
})
export class EarningTimerModule {}
