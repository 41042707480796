import { DaybeamUtil } from '../DaybeamUtil';

export const API_CONSTANTS = {
  API_ENDPOINT_URL: DaybeamUtil.getApiEndPoint(),
  OAUTH_TOKEN_URL: DaybeamUtil.getApiEndPointPath() + '/api/oauth/token',
  OAUTH_URL: DaybeamUtil.getApiEndPoint() + '/api/oauth',
  AUTH_USER: DaybeamUtil.getApiEndPoint() + '/auth/users',
  AUTH_SEAMLESS: DaybeamUtil.getApiEndPoint() + '/api/oauth/seamless',
  TIMER_ENTRIES_URL: DaybeamUtil.getApiEndPoint() + '/time-entries',
  USERS_URL: DaybeamUtil.getApiEndPoint() + '/users',
  LOGOUT: DaybeamUtil.getApiEndPoint() + '/api/oauth/logout',
  OPENAI_URL: DaybeamUtil.getApiEndPoint() + '/api/openai',
};
