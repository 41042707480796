<div class="login-wrapper">
  <app-header class="topnavbar-wrapper" [onlyLogo]="true"></app-header>
  <h1 class="login-title">{{ "labels.login" | translate }}</h1>
  <div class="row justify-content-center login-form-wrapper">
    <div class="col-md-6">
      <div class="container login-body" *ngIf="loginForm">
        <div class="justify-content-center">
          <form
            class="form-main"
            [formGroup]="loginForm"
            (submit)="submitForm($event)"
          >
            <div class="form-group pb-1">
              <label class="label-required" for="email">{{
                "labels.email" | translate
              }}</label>
              <span class="text-danger pl-1">*</span>
              <div class="input-group with-focus">
                <input
                  type="email"
                  id="email"
                  formControlName="email"
                  class="form-control"
                  autocomplete="off"
                />
                <div class="input-group-append border-left">
                  <span class="input-group-text text-muted bg-white">
                    <em class="fa fa-envelope label-icons"></em>
                  </span>
                </div>
              </div>
              <div
                *ngIf="
                  loginForm.controls['email'].hasError('required') &&
                  (loginForm.controls['email'].dirty ||
                    loginForm.controls['email'].touched)
                "
                class="text-danger"
              >
                {{ "errors.required" | translate }}
              </div>
              <div
                *ngIf="
                  loginForm.controls['email'].hasError('email') &&
                  (loginForm.controls['email'].dirty ||
                    loginForm.controls['email'].touched)
                "
                class="text-danger"
              >
                {{ "errors.valid_email" | translate }}
              </div>
            </div>
            <div class="form-group password-field">
              <label class="label-required" for="password">{{
                "labels.password" | translate
              }}</label>
              <span class="text-danger pl-1">*</span>
              <div class="input-group with-focus">
                <input
                  type="password"
                  id="password"
                  formControlName="password"
                  class="form-control"
                  autocomplete="off"
                />
                <div class="input-group-append border-left">
                  <span class="input-group-text text-muted bg-white">
                    <em class="fa fa-key label-icons"></em>
                  </span>
                </div>
              </div>
              <div
                *ngIf="
                  loginForm.controls['password'].hasError('required') &&
                  (loginForm.controls['password'].dirty ||
                    loginForm.controls['password'].touched)
                "
                class="text-danger"
              >
                {{ "errors.required" | translate }}
              </div>
            </div>
            <div
              class="d-flex justify-content-between align-items-center flex-direction"
            >
              <div>
                <label class="forgot-password-label">
                  <a
                    class="primary-color text-decoration-underline"
                    routerLink="/password/reset"
                    >{{ "labels.forgot_password" | translate }}</a
                  >
                </label>
              </div>
              <div class="justify-content-end btn-group-wrapper">
                <button
                  [disabled]="!loginForm.valid || loginFormLoading"
                  class="btn primary-background d-flex align-items-center login-btn p-0"
                >
                  <em class="far fa-check-circle check-icon text-white"></em>
                  <p class="mb-0 login-btn-text text-white">
                    {{ "labels.login" | translate }}
                  </p>
                </button>
              </div>
            </div>
            <div *ngIf="oError" class="mt-2">
              <alert *ngIf="oError?.message" [dismissible]="true" type="danger">
                {{ oError?.message }}
              </alert>
            </div>
          </form>
        </div>
        <div class="d-flex justify-content-center dont-have-account text-black">
          <span class="pr-2">
            <i class="fas fa-user-tie text-black"></i>
          </span>
          <p class="label-text">{{ "labels.dont_have_account" | translate }}</p>
        </div>
        <a
          routerLink="/signup"
          class="d-flex justify-content-center primary-color btn-labled"
          ><h5 class="mb-0 signup-btn">
            {{ "labels.signup_header" | translate }}
          </h5></a
        >
      </div>
    </div>
  </div>
</div>
<div class="footer-text">
  <span class="copyright"
  >&copy; {{ settings.getAppSetting("year") }} -
  {{ settings.getAppSetting("name") }}.</span
 >
 <a class="contact" target=”_blank” href="https://www.daybeam.com/contact/">Contact</a>
 <a class="privacy-policy" target=”_blank” href="https://www.daybeam.com/privacy-policy/">Privacy Policy</a>
 <a class="terms" target=”_blank” href="https://www.daybeam.com/terms/">Terms</a>
</div>

