import { Injectable } from '@angular/core';
import { CustomHttpService } from './custom.http.service';
import { API_CONSTANTS } from '../common/constant/ApiConstants';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  constructor(private _customHttpService: CustomHttpService) {}

  getStatsList(workspaceId,oParams?,includes?) {
    const params = new URLSearchParams();
    for (const key in oParams) {
      if (Array.isArray(oParams[key])) {
        oParams[key].forEach((val) => {
          params.append(key, val);
        });
      } else if (
        typeof oParams[key] === 'object' &&
        !Array.isArray(oParams[key]) &&
        oParams[key] !== null
      ) {
        for (const raw in oParams[key]) {
          params.set(key + '[' + raw + ']', oParams[key][raw]);
        }
      } else {
        params.set(key, oParams[key]);
      }
    }

  for (const key in includes) {
    params.append(key, includes[key]);
}

    return this._customHttpService.get(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/reports/time-entries/stats' +
        (params.toString() ? '?' + params.toString() : '')
    );
  }

  getTaskUserList(workspaceId, id, page = 1) {
    return this._customHttpService.get(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/tasks/' +
        id +
        '/users' +
        (page > 1 ? '?page=' + page : '')
    );
  }

  getTaskUserStatList(workspaceId, id,oParams?) {
    const params = new URLSearchParams();
    for (const key in oParams) {
      params.set(key, oParams[key]);
    }
    return this._customHttpService.get(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/tasks/' +
        id +
        '/users/stats' +
      (params.toString() ? '?' + params.toString() : '')
    );
  }

  getFilterTransactionList(workspaceId, page = 1, oParams,include,perpage?,sortData?) {
    const params = new URLSearchParams();
    for (const key in oParams) {
      if (Array.isArray(oParams[key])) {
        oParams[key].forEach((val) => {
          params.append(key, val);
        });
      } else if (
        typeof oParams[key] === 'object' &&
        !Array.isArray(oParams[key]) &&
        oParams[key] !== null
      ) {
        for (const raw in oParams[key]) {
          params.set(key + '[' + raw + ']', oParams[key][raw]);
        }
      } else {
        params.set(key, oParams[key]);
      }
    }
    for (const key in include) {
        params.append('include[]', include[key]);
    }

    for (const key in sortData) {
      params.append(key, sortData[key]);
  }

    return this._customHttpService.get(
      API_CONSTANTS.API_ENDPOINT_URL +
      '/api/workspaces/' +
      workspaceId +
      '/time-entries' +
      (params.toString() ? '?' + params.toString() : '') +
      (page > 1 ? '&page=' + page : '') +
      (perpage ? '&per_page=' + perpage : '')

    //   (page > 1 && perpage > 10 ? ((!!oParams ? Object.keys(oParams).length : !!oParams) ? '&page=' + page + '&per_page=' + perpage : '?page=' + page + '&per_page=' + perpage ) :
    //   (page > 1 ? ((!!oParams ? Object.keys(oParams).length : !!oParams)  ? '&page=' + page : '?page=' + page ) : (perpage > 10 ? ((!!oParams ? Object.keys(oParams).length : !!oParams)  ? '&per_page=' + perpage : '?per_page=' + perpage ) : ''))
    // )
    );
  }

  updateTimeEntry(workspaceId, id, oData) {
    return this._customHttpService.put(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/time-entries/' +
        id,
      oData
    );
  }

  deleteTimeEntry(workspaceId, id) {
    return this._customHttpService.delete(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/time-entries/' +
        id
    );
  }

  downloadTransactionToken(workspaceId, oData, oParams?) {
    const params = new URLSearchParams();
    for (const key in oParams) {
      params.set(key, oParams[key]);
    }
    return this._customHttpService.post(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/download-tokens' +
        (params.toString() ? '?' + params.toString() : ''),
      oData
    );
  }

  updateMarkTransactionAsPaid(workspaceId, oData) {
    return this._customHttpService.put(
      API_CONSTANTS.API_ENDPOINT_URL +
        '/api/workspaces/' +
        workspaceId +
        '/time-entries/actions/mark-as-paid',
      oData
    );
  }

  downloadTranactionReport(workspaceId, token, params?) {
    const aparams = new URLSearchParams();

    for (const key in params) {
      aparams.set(key, params[key]);
    }
    let url =
      API_CONSTANTS.API_ENDPOINT_URL +
      `/api/workspaces/${workspaceId}/download/${token}` +
      (aparams.toString() ? '?' + aparams.toString() : '');
    window.open(url, '_blank');
  }

  openAIChatCompletions(oData) {
    return this._customHttpService.post(API_CONSTANTS.OPENAI_URL + '/chat/completions',
        oData
    );
  }
}
