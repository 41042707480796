import { ToasterService } from 'angular2-toaster';
import { LoginService } from './services/login.service';
import { SignupService } from './services/signup.service';
import { CustomHttpService } from './services/custom.http.service';
import { LocalStorageService } from './services/localstorage.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  TranslateService,
  TranslateModule,
  TranslateLoader,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { ApiService } from './services/common/api.service';
import { ExternalScriptService } from './services/common/external-script.service';
import { GroupService } from './services/group.service';
import { CommonService } from './services/common/common.service';
import { LoaderInterceptor } from './services/loader.interceptor';
import { TitleService } from './services/title/title.service';
import {FormsModule} from "@angular/forms";

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    LayoutModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    SharedModule.forRoot(),
    RoutesModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [TranslateModule],
  providers: [
    LocalStorageService,
    CustomHttpService,
    TranslateService,
    SignupService,
    LoginService,
    ApiService,
    CommonService,
    ExternalScriptService,
    ToasterService,
    GroupService,
    {
      provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(public titleService: TitleService) {}
}
