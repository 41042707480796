<div class="bg-white">
  <div class="text-black extension-title">
    {{ "labels.google_chrome_extension" | translate }}
  </div>
  <div class="chrome-extension-body">
    <h4 class="text-black task-suggestion">{{ "labels.task_suggestion_logic" | translate }}</h4>
    <div class="smart-suggest-wrapper">
    <ng-select
          #select
          class="smart-suggestion"
          (change)="onChange($event)"
          [items]="smartSuggestionList"
          bindLabel="label"
          bindValue="label"
          [searchable]="false"
          placeholder=""
          [(ngModel)]="taskSuggestion"
        >
        <ng-template ng-label-tmp let-item="item">
          <div class="d-flex align-items-center justify-content-between selected-title">
            <span class="workspace-title">
              <span class="workspace-title-name">
                {{item.label}}</span>
              </span>
              <em class="fa fa-angle-down arrow-icon" aria-hidden="true"></em>
          </div>
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-index="index">
          <div class="suggestion-dropdown-option">
            <div>
              <p class="suggestion-option-title mb-0" title="{{ item.label }}">{{
                item.label
              }}</p>
            </div>
          </div>
        </ng-template>
      </ng-select>
      </div>
  </div>
</div>
