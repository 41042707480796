<form [formGroup]="TimerForm">
  <div
    class="timer-main"
    [ngStyle]="{
      display: !isStartTimerLoading && !showSwitchTimer ? 'block' : 'none'
    }"
  >
    <div
      class="btn-group d-flex"
      dropdown
      data-keyboard="true"
      data-backdrop="static"
    >
      <div class="w-100" *ngIf="!isStartTimerLoading && timerDataLoaded">
        <button
          placement="bottom"
          *ngIf="!fromDashboard"
          [tooltip]="roleId == primaryUserRoleId ? 'Optional: Track your own time' : 'Click here to start the timer'"
          [ngClass]="
            userRoleId == 3 && !!workspaceArchived
              ? 'disabled-timer'
              : 'timer-btn'
          "
          class="btn btn-block start-timer-button d-flex align-items-center clickable pl-3"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          (click)="openTimerModel(); isActiveTab = true; manualTab = false"
        >
          <i class="far fa-clock fa-2x"></i>
          <span
            class="pl-2 d-flex align-items-center justify-content-center start-timer"
            >Start Timer</span
          >
        </button>
      </div>
      <div
        class="dropdown-menu dropdown-menu-left"
        id="timerTab"
        aria-labelledby="dropdownMenuLink">
        <div class="timer-btn-group-tab">
          <button
            class="btn btn-sm tab-btn timer-tab"
            id="timer-click"
            type="button"
            [ngClass]="{ active: isActiveTab }"
            (click)="timer()"
          >
            Timer
          </button>
          <button
            class="btn btn-sm tab-btn manual-tab"
            type="button"
            [ngClass]="{ active: !isActiveTab }"
            (click)="manual()"
          >
            Manual
          </button>
        </div>

        <div *ngIf="isActiveTab" class="manual-body">
          <div class="description-field">
            <label class="text-black mb-3">Task:</label>
            <div class="d-flex position-relative">
              <input
                name="test"
                class="form-control input-field-task"
                type="text"
                placeholder="Please add the task you’re working on"
                formControlName="autoTimerForm"
                autocomplete="off"
                #search
                (input)="
                  startTimerGetProject(TimerForm.value.autoTimerForm, 'auto')
                "
              />
              <button
                [disabled]="!isBtnLoading"
                type="button"
                class="btn btn-primary start-timer-popup"
                (click)="startAutoTimer()"
              >
                Start Timer
              </button>
            </div>
            <div class="task-dropdown-wrapper">
              <div
                *ngIf="isModalLoading && searchData?.length && searchLength > 0"
                id="searchList"
                class="searchList"
              >
                <div *ngFor="let item of searchData">
                  <div
                    class="d-flex justify-content-between task-details searched-task"
                    (click)="changeTask(item)"
                  >
                    <span
                      title="{{ item.title }}"
                      class="task-title task-timer"
                      >{{ item.title }}</span
                    >
                    <span
                      title="{{ item.groupName }}"
                      class="groups-name group-timer"
                      >{{ item.groupName }}</span
                    >
                  </div>
                </div>
              </div>
              <div (click)="openmodel()">
                <div
                  (click)="addNewTask(searchValue, 'auto')"
                  [ngClass]="[
                    searchData?.length == 0 ? 'search-task-border' : ''
                  ]"
                  *ngIf="
                  isModalLoading &&
                    userGroupList.length == 0 &&
                    searchLength > 0
                  "
                  id="addNewSearchList"
                  class="addNewSearchList task-details"
                >
                  <div
                    class="searched-task"
                    [ngClass]="!!searchData?.length ? 'add-search-task' : ''"
                  >
                    <em class="fas fa-plus plus-icon"></em>
                    <span
                      title="{{ searchValue }}"
                      class="task-title search-text task-timer"
                      >{{ searchValue }}</span
                    >
                  </div>
                </div>
              </div>

              <div
                (click)="openmodel()"
                [ngClass]="[
                  searchData?.length == 0 ? 'search-taskgroup-border' : ''
                ]"
                *ngIf="
                isModalLoading &&
                  searchLength > 0 &&
                  userGroupList.length != 0
                "
                id="addNewSearchList"
                class="addNewSearchList"
              >
                <div [ngClass]="!!searchData?.length ? 'search-wrapper' : ''">
                  <div (click)="addNewTask(searchValue, 'auto')" *ngIf="!searchData?.length || !taskExist" class="searched-task">
                    <em class="fas fa-plus plus-icon"></em>
                    <span
                      title="{{ searchValue }}"
                      class="task-title search-text task-timer"
                      >{{ searchValue }}</span
                    >
                  </div>
                  <div (click)="addNewTask(searchValue, 'auto')" *ngIf="(!!groupExist)" class="searched-task">
                    <em class="fas fa-plus plus-icon"></em>
                    <span
                      title="{{ searchValue }}"
                      class="task-title search-text task-timer"
                      >{{ searchValue }}</span
                    >
                  </div>
                  <div
                    class="search-task-list"
                    *ngFor="let item of userGroupList"
                  >
                    <div
                      class="d-flex justify-content-between task-details searched-task"
                      (click)="addNewTaskWithGroup(searchValue, item, 'auto')"
                    >
                      <div class="search-task-wrapper">
                        <em class="fas fa-plus plus-icon"></em>
                        <span
                          title="{{ searchValue }}"
                          class="task-title search-text task-timer"
                          >{{ searchValue }}</span
                        >
                      </div>
                      <span title="{{ item.name }}" class="search-task-group">{{
                        item.name
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-footer">
            <span class="billable-checkbox">
              <input
                formControlName="billableHour"
                class="checkbox"
                type="checkbox"
                [checked]="TimerForm.value.billableHour"
              />
            </span>
              <p class="billable-hour text-black">Billable Hours</p>
            </div>
          </div>
        </div>

        <div *ngIf="manualTab" class="timer-body">
          <div class="more-text">
            <p class="text-black mb-1">
              Manually add time that you might have missed and a task.
            </p>
          </div>

          <div class="justify-content-between mt-3 pt-1 row mr-0 ml-0">
            <div class="start-date-wrapper col-4">
            <input type="text"
            placeholder="Start Date"
            class="form-control start-date-field"
            bsDatepicker
            formControlName="startDate"
            (click)="onDateModalSelection()"
            [bsValue]="bsValue"
            [bsConfig]="datePickerConfig"
            (bsValueChange)="startDateChangeEvent($event)"
            >
            </div>
            <div class="col-4 hour-field">
              <input
                name="test"
                type="number"
                class="form-control input-field"
                formControlName="manualHours"
                placeholder="HH"
                autocomplete="off"
              />
            </div>

            <div class="d-flex flex-column col-4 minute-field">
              <input
                id="minutes"
                type="integer"
                pattern="[0-9]*"
                class="form-control input-field"
                formControlName="manualMinutes"
                placeholder="MM"
                autocomplete="off"
                maxlength="2"
              />
              <div
                *ngIf="
                  (TimerForm.controls['manualMinutes'].hasError('max') ||
                  TimerForm.controls['manualMinutes'].hasError('min')) &&
                  (TimerForm.controls['manualMinutes'].dirty ||
                    TimerForm.controls['manualMinutes'].touched)
                "
                class="text-danger"
              >
                Minutes should be between 0 to 60
              </div>
              <div
              *ngIf="
                !(TimerForm.controls['manualMinutes'].hasError('max') ||
                TimerForm.controls['manualMinutes'].hasError('min')) &&
                (TimerForm.controls['manualMinutes'].dirty ||
                  TimerForm.controls['manualMinutes'].touched)
                  && TimerForm.controls['manualMinutes'].invalid && !!TimerForm.controls['manualMinutes'].value
              "
              class="text-danger"
            >
              Please enter Number value
            </div>
            </div>
          </div>
          <!-- <div class="d-flex justify-content-between">
            <div class="w-50 mr-4 pr-1">
              <input
                name="test"
                type="number"
                class="form-control input-field"
                formControlName="manualHours"
                placeholder="HH"
                autocomplete="off"
              />
            </div>

            <div class="d-flex flex-column w-50">
              <input
                id="minutes"
                type="integer"
                pattern="[0-9]*"
                class="form-control input-field"
                formControlName="manualMinutes"
                placeholder="MM"
                autocomplete="off"
                maxlength="2"
              />
              <div
                *ngIf="
                  (TimerForm.controls['manualMinutes'].hasError('max') ||
                  TimerForm.controls['manualMinutes'].hasError('min')) &&
                  (TimerForm.controls['manualMinutes'].dirty ||
                    TimerForm.controls['manualMinutes'].touched)
                "
                class="text-danger"
              >
                Minutes should be between 0 to 60
              </div>
              <div
              *ngIf="
                !(TimerForm.controls['manualMinutes'].hasError('max') ||
                TimerForm.controls['manualMinutes'].hasError('min')) &&
                (TimerForm.controls['manualMinutes'].dirty ||
                  TimerForm.controls['manualMinutes'].touched)
                  && TimerForm.controls['manualMinutes'].invalid && !!TimerForm.controls['manualMinutes'].value
              "
              class="text-danger"
            >
              Please enter Number value
            </div>
            </div>
          </div> -->

          <div
            class="form-group mt-3 pt-1 d-flex align-items-end justify-content-between mb-0"
          >
            <div class="description-field d-flex">
              <input
                name="test"
                type="text"
                class="form-control input-field-add-time"
                formControlName="autoTimerForm"
                autocomplete="off"
                #search
                (input)="
                  startTimerGetProject(TimerForm.value.autoTimerForm, 'auto')
                "
                placeholder="Please add the task you worked on"
              />
              <button
                [disabled]="
                  !(
                    isManualBtnLoading &&
                    isValid
                  )
                "
                type="button"
                class="btn btn-primary start-timer-popup"
                (click)="startManualTimer()"
              >
                Add Time
              </button>
            </div>
          </div>
          <div class="task-dropdown-wrapper">
          <div
            *ngIf="isModalLoading && searchData?.length && searchLength > 0"
            class="searchList"
            id="searchList"
          >
            <div *ngFor="let item of searchData">
              <div
                class="d-flex justify-content-between task-details searched-task"
                (click)="changeTask(item)"
              >
                <span title="{{ item.title }}" class="task-title task-timer">{{
                  item.title
                }}</span>
                <span
                  title="{{ item.groupName }}"
                  class="groups-name group-timer"
                  >{{ item.groupName }}</span
                >
              </div>
            </div>
          </div>

          <div (click)="openmodel()">
            <div
              (click)="addNewTask(searchValue, 'auto')"
              [ngClass]="searchData?.length == 0 ? 'search-task-border' : ''"
              *ngIf="
              isModalLoading &&
                userGroupList.length == 0 &&
                searchLength > 0
              "
              id="addNewSearchList"
              class="addNewSearchList task-details"
            >
              <div
                class="searched-task"
                [ngClass]="!!searchData?.length ? 'add-search-task' : ''"
              >
                <em class="fas fa-plus plus-icon"></em>
                <span
                  title="{{ searchValue }}"
                  class="task-title search-text task-timer"
                  >{{ searchValue }}</span
                >
              </div>
            </div>
          </div>

          <div
            (click)="openmodel()"
            [ngClass]="searchData?.length == 0 ? 'search-taskgroup-border' : ''"
            *ngIf="
            isModalLoading && searchLength > 0 && userGroupList.length != 0
            "
            id="addNewSearchList"
            class="addNewSearchList"
          >
            <div [ngClass]="!!searchData?.length ? 'search-wrapper' : ''">
            <div (click)="addNewTask(searchValue, 'auto')" *ngIf="!searchData?.length" class="searched-task">
                    <em class="fas fa-plus plus-icon"></em>
                    <span
                      title="{{ searchValue }}"
                      class="task-title search-text task-timer"
                      >{{ searchValue }}</span
                    >
                  </div>
                  <div (click)="addNewTask(searchValue, 'auto')" *ngIf="(!!groupExist)" class="searched-task">
                    <em class="fas fa-plus plus-icon"></em>
                    <span
                      title="{{ searchValue }}"
                      class="task-title search-text task-timer"
                      >{{ searchValue }}</span
                    >
                  </div>
              <div class="search-task-list" *ngFor="let item of userGroupList">
                <div
                  class="d-flex justify-content-between task-details searched-task"
                  (click)="addNewTaskWithGroup(searchValue, item, 'auto')"
                >
                  <div class="search-task-wrapper">
                    <em class="fas fa-plus plus-icon"></em>
                    <span
                      title="{{ searchValue }}"
                      class="task-title search-text task-timer"
                      >{{ searchValue }}</span
                    >
                  </div>
                  <span title="{{ item.name }}" class="search-task-group">{{
                    item.name
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div class="tab-footer">
            <span class="billable-checkbox">
            <input
              class="checkbox"
              type="checkbox"
              formControlName="billableHour"
              [checked]="TimerForm.value.billableHour"
            />
          </span>
            <p class="billable-hour text-black">Billable Hours</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="fromDashboard && !isStartTimerLoading">
    <div class="description-field" *ngIf="!switchedToManualOnDashboard">
      <div class="d-flex position-relative">
        <input
          name="test"
          class="form-control input-field-task"
          type="text"
          placeholder="Please add the task you’re working on"
          formControlName="autoTimerForm"
          autocomplete="off"
          #search
          (input)="
            startTimerGetProject(TimerForm.value.autoTimerForm, 'auto')
          "
        />
        <button
          [disabled]="!isBtnLoading"
          type="button"
          class="btn ai_options_btn_color ml-2 text-white"
          (click)="startAutoTimer(); newUserTaskStopped = false; showTask = true"
        >
          Start
        </button>
        <div class="col clickable px-2" (click)="switchToManual()">
          <div class="mt-2">
            <em class="fa-1x fas fa-long-arrow-alt-left"></em>
          </div>
          <div style="margin-top: -1rem;">
            <em class="fa-1x fas fa-long-arrow-alt-right"></em>
          </div>
        </div>
      </div>
      <div class="task-dropdown-wrapper col-11 px-0" *ngIf="!switchedToManualOnDashboard">
        <div
          *ngIf="isModalLoading && searchData?.length && searchLength > 0"
          id="searchList"
          class="searchList"
        >
          <div *ngFor="let item of searchData">
            <div
              class="d-flex justify-content-between task-details searched-task"
              (click)="changeTask(item)"
            >
              <span
                title="{{ item.title }}"
                class="task-title task-timer"
                >{{ item.title }}</span
              >
              <span
                title="{{ item.groupName }}"
                class="groups-name group-timer"
                >{{ item.groupName }}</span
              >
            </div>
          </div>
        </div>
        <div (click)="openmodel()">
          <div
            (click)="addNewTask(searchValue, 'auto')"
            [ngClass]="[
              searchData?.length == 0 ? 'search-task-border' : ''
            ]"
            *ngIf="
            isModalLoading &&
              userGroupList.length == 0 &&
              searchLength > 0
            "
            id="addNewSearchList"
            class="addNewSearchList task-details"
          >
            <div
              class="searched-task"
              [ngClass]="!!searchData?.length ? 'add-search-task' : ''"
            >
              <em class="fas fa-plus plus-icon"></em>
              <span
                title="{{ searchValue }}"
                class="task-title search-text task-timer"
                >{{ searchValue }}</span
              >
            </div>
          </div>
        </div>

        <div
          (click)="openmodel()"
          [ngClass]="[
            searchData?.length == 0 ? 'search-taskgroup-border' : ''
          ]"
          *ngIf="
          isModalLoading &&
            searchLength > 0 &&
            userGroupList.length != 0
          "
          id="addNewSearchList"
          class="addNewSearchList"
        >
          <div [ngClass]="!!searchData?.length ? 'search-wrapper' : ''">
            <div (click)="addNewTask(searchValue, 'auto')" *ngIf="!searchData?.length || !taskExist" class="searched-task">
              <em class="fas fa-plus plus-icon"></em>
              <span
                title="{{ searchValue }}"
                class="task-title search-text task-timer"
                >{{ searchValue }}</span
              >
            </div>
            <div (click)="addNewTask(searchValue, 'auto')" *ngIf="(!!groupExist)" class="searched-task">
              <em class="fas fa-plus plus-icon"></em>
              <span
                title="{{ searchValue }}"
                class="task-title search-text task-timer"
                >{{ searchValue }}</span
              >
            </div>
            <div
              class="search-task-list"
              *ngFor="let item of userGroupList"
            >
              <div
                class="d-flex justify-content-between task-details searched-task"
                (click)="addNewTaskWithGroup(searchValue, item, 'auto')"
              >
                <div class="search-task-wrapper">
                  <em class="fas fa-plus plus-icon"></em>
                  <span
                    title="{{ searchValue }}"
                    class="task-title search-text task-timer"
                    >{{ searchValue }}</span
                  >
                </div>
                <span title="{{ item.name }}" class="search-task-group">{{
                  item.name
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-footer" *ngIf="!switchedToManualOnDashboard">
      <span class="billable-checkbox">
        <input
          formControlName="billableHour"
          class="checkbox"
          type="checkbox"
          [checked]="TimerForm.value.billableHour"
        />
      </span>
        <p class="billable-hour text-black">Billable Hours</p>
      </div>
    </div>

    <div *ngIf="switchedToManualOnDashboard" class="px-3 row pb-0">

      <div class="form-group mb-0 col-xl-7 pl-0">
        <div class="description-field d-flex">
          <input
            name="test"
            type="text"
            class="form-control height-40"
            formControlName="autoTimerForm"
            autocomplete="off"
            #search
            (input)="
              startTimerGetProject(TimerForm.value.autoTimerForm, 'auto')
            "
            placeholder="Please add the task you worked on"
          />
        </div>
      </div>
      <div class="col-xl-5 mr-0 ml-0 row justify-content-between pl-0">
        <div class="col-3 px-0 px-0">
          <input type="text"
          placeholder="Start Date"
          class="form-control height-40"
          bsDatepicker
          formControlName="startDate"
          (click)="onDateModalSelection()"
          [bsValue]="bsValue"
          [bsConfig]="datePickerConfig"
          (bsValueChange)="startDateChangeEvent($event)"
          >
        </div>
        <div class="col-3 px-0">
          <input
            name="test"
            type="number"
            class="form-control height-40"
            formControlName="manualHours"
            placeholder="HH"
            autocomplete="off"
          />
        </div>

        <div class="col-3 pl-0 px-0">
          <input
            id="minutes"
            type="integer"
            pattern="[0-9]*"
            class="form-control height-40"
            formControlName="manualMinutes"
            placeholder="MM"
            autocomplete="off"
            maxlength="2"
          />
          <div
            *ngIf="
              (TimerForm.controls['manualMinutes'].hasError('max') ||
              TimerForm.controls['manualMinutes'].hasError('min')) &&
              (TimerForm.controls['manualMinutes'].dirty ||
                TimerForm.controls['manualMinutes'].touched)
            "
            class="text-danger">
            Minutes should be between 0 to 60
          </div>
          <div
            *ngIf="
            !(TimerForm.controls['manualMinutes'].hasError('max') ||
            TimerForm.controls['manualMinutes'].hasError('min')) &&
            (TimerForm.controls['manualMinutes'].dirty ||
            TimerForm.controls['manualMinutes'].touched)
            && TimerForm.controls['manualMinutes'].invalid && !!TimerForm.controls['manualMinutes'].value"
            class="text-danger">
            Please enter Number value
          </div>
        </div>
        <div class="row justify-content-between pl-2">
          <button
            [disabled]="
              !(
                isManualBtnLoading &&
                isValid
              )
            "
            type="button"
            class="btn ai_options_btn_color ml-2 text-white height-40"
            (click)="startManualTimer()"
            style="width: 4rem;"
          >
            Add
          </button>
          <div class="col clickable px-2" (click)="switchToManual()">
            <div class="mt-2">
              <em class="fa-1x fas fa-long-arrow-alt-left"></em>
            </div>
            <div style="margin-top: -1rem;">
              <em class="fa-1x fas fa-long-arrow-alt-right"></em>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="task-dropdown-wrapper col-xl-7 pl-0" *ngIf="switchedToManualOnDashboard">
      <div
        *ngIf="isModalLoading && searchData?.length && searchLength > 0"
        class="searchList"
        id="searchList">
        <div *ngFor="let item of searchData">
          <div
            class="d-flex justify-content-between task-details searched-task"
            (click)="changeTask(item)"
          >
            <span title="{{ item.title }}" class="task-title task-timer">{{
              item.title
            }}</span>
            <span
              title="{{ item.groupName }}"
              class="groups-name group-timer"
              >{{ item.groupName }}</span
            >
          </div>
        </div>
      </div>

      <div (click)="openmodel()">
        <div
          (click)="addNewTask(searchValue, 'auto')"
          [ngClass]="searchData?.length == 0 ? 'search-task-border' : ''"
          *ngIf="
          isModalLoading &&
            userGroupList.length == 0 &&
            searchLength > 0
          "
          id="addNewSearchList"
          class="addNewSearchList task-details"
        >
          <div
            class="searched-task"
            [ngClass]="!!searchData?.length ? 'add-search-task' : ''"
          >
            <em class="fas fa-plus plus-icon"></em>
            <span
              title="{{ searchValue }}"
              class="task-title search-text task-timer"
              >{{ searchValue }}</span
            >
          </div>
        </div>
      </div>

      <div
        (click)="openmodel()"
        [ngClass]="searchData?.length == 0 ? 'search-taskgroup-border' : ''"
        *ngIf="
        isModalLoading && searchLength > 0 && userGroupList.length != 0
        "
        id="addNewSearchList"
        class="addNewSearchList">
        <div [ngClass]="!!searchData?.length ? 'search-wrapper' : ''">
        <div (click)="addNewTask(searchValue, 'auto')" *ngIf="!searchData?.length" class="searched-task">
                <em class="fas fa-plus plus-icon"></em>
                <span
                  title="{{ searchValue }}"
                  class="task-title search-text task-timer"
                  >{{ searchValue }}</span
                >
              </div>
              <div (click)="addNewTask(searchValue, 'auto')" *ngIf="(!!groupExist)" class="searched-task">
                <em class="fas fa-plus plus-icon"></em>
                <span
                  title="{{ searchValue }}"
                  class="task-title search-text task-timer"
                  >{{ searchValue }}</span
                >
              </div>
          <div class="search-task-list" *ngFor="let item of userGroupList">
            <div
              class="d-flex justify-content-between task-details searched-task"
              (click)="addNewTaskWithGroup(searchValue, item, 'auto')"
            >
              <div class="search-task-wrapper">
                <em class="fas fa-plus plus-icon"></em>
                <span
                  title="{{ searchValue }}"
                  class="task-title search-text task-timer"
                  >{{ searchValue }}</span
                >
              </div>
              <span title="{{ item.name }}" class="search-task-group">{{
                item.name
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-footer" *ngIf="switchedToManualOnDashboard">
      <span class="billable-checkbox">
        <input
          class="checkbox"
          type="checkbox"
          formControlName="billableHour"
          [checked]="TimerForm.value.billableHour"/>
      </span>
      <p class="billable-hour text-black">Billable Hours</p>
    </div>
  </div>
  <div>
    <div
      *ngIf="isStartTimerLoading && (!newUserTaskStopped || fromDashboardNotNewUser)"
      [ngClass]="isStartTimerLoading ? 'timer-spacing' : ''"
      class="d-flex row px-3 justify-content-between"
      [ngClass]="!fromDashboard ? 'start-timer-wrapper' : ''"
      id="start-timer-wrapper">
      <div class="timer-wrapper" [tooltip]="selectedTaskName"
        *ngIf="!fromDashboard && !timerSwitchedOnDashboard">
        <img
          src="../../../../assets/img/hourglass-end-solid.svg"
          class="hourglass-icon"
          alt="hourglass"
        />
        <span class="timer-text">{{ countDownTime }}</span>
      </div>
      <div class="p-0 col-xl-11 widened_popover d-flex justify-content-between align-items-center"
        *ngIf="fromDashboard && !timerSwitchedOnDashboard">
        <span>
          <span *ngIf="newUser">
            Setting up and configuring Daybeam's time tracking system
          </span>
          <span *ngIf="!newUser">
            {{selectedTaskName}}
          </span>
        </span>
        <span class="text-dark pl-2"[popover]="initiatingText" placement="left"
        [isOpen]="isPopOverOpen" *ngIf="newUser">{{ countDownTime }}</span>
        <span class="text-dark pl-2" *ngIf="!newUser">{{ countDownTime }}</span>
      </div>
      <div class="d-flex">
        <div
          (click)="switchTask(); showTask= false"
          [tooltip]="'Switch to another Task'"
          [placement]="fromDashboard ? 'top' : 'bottom'"
          container="body"
          class="toggle-icon"
          [ngClass]="fromDashboard ? 'rounded-left' : ''"
          *ngIf="!timerSwitchedOnDashboard">
          <img src="../../../../assets/img/toggle-on-solid.svg" alt="toggle" />
        </div>
        <button
          [attr.data-daybeam-workspace-id]="workspaceId"
          (click)="StopTimer(); showTask= false"
          [disabled]="isStopTimerLoading"
          [tooltip]="'Stop Timer'"
          [placement]="fromDashboard ? 'top' : 'bottom'"
          container="body"
          class="stop-icon" id="daybeam-workspace-stop-timer-button"
          [ngClass]="{'btn-disabled-opacity' : isStopTimerLoading, 'rounded-right' : fromDashboard}"
          *ngIf="!timerSwitchedOnDashboard">
          <img src="../../../../assets/img/stop-solid.svg" alt="stop-timer" />
        </button>
      </div>
      <div class="col-12 px-2 pr-0"
        *ngIf="timerSwitchedOnDashboard">
      <div class="switch-task-wrapper p-0">
        <div class="d-flex">
          <input
            name="test"
            type="text"
            class="input-field col-xl-9"
            formControlName="switchTimerForm"
            autocomplete="off"
            #search
            (input)="
              startTimerGetProject(TimerForm.value.switchTimerForm, 'switch')
            "
            placeholder="Please add the task you are switching too"
          />

          <div class="d-flex align-items-center pl-2">
            <span class="text-dark">{{ countDownTime }}</span>
          </div>

          <button
            [disabled]="!isSwitchBtnLoading"
            [ngClass]="!isSwitchBtnLoading ? 'switch-task-btn' : ''"
            (click)="SwitchTaskTimer()"
            class="dashboard-start-timer-btn"
          >
            Switch Task
          </button>
          <button
            (click)="timerSwitchedOnDashboard = false"
            class="dashboard-back-timer-btn"
          >
            Back
          </button>
        </div>
        <div class="task-dropdown-wrapper col-xl-10 px-0">
        <div
          *ngIf="isSwitchmodalLoading && searchData?.length && searchLength > 0"
          class="searchList"
          id="searchList"
        >
          <div *ngFor="let item of searchData">
            <div
              class="d-flex justify-content-between task-details searched-task"
              (click)="changeSwitchTask(item)"
            >
              <span title="{{ item.title }}" class="task-title task-timer">{{
                item.title
              }}</span>
              <span
                title="{{ item.groupName }}"
                class="groups-name group-timer"
                >{{ item.groupName }}</span
              >
            </div>
          </div>
        </div>

        <div (click)="openmodel()">
          <div
            (click)="addNewTask(searchValue, 'switch')"
            [ngClass]="searchData?.length == 0 ? 'search-task-border' : ''"
            *ngIf="
              isSwitchmodalLoading &&
              userGroupList.length == 0 &&
              searchLength > 0
            "
            id="addNewSearchList"
            class="addNewSearchList task-details"
          >
            <div
              class="searched-task"
              [ngClass]="!!searchData?.length ? 'add-search-task' : ''"
            >
              <em class="fas fa-plus plus-icon"></em>
              <span class="task-title search-text task-timer">{{
                searchValue
              }}</span>
            </div>
          </div>
        </div>

        <div
          (click)="openmodel()"
          [ngClass]="searchData?.length == 0 ? 'search-taskgroup-border' : ''"
          *ngIf="
            isSwitchmodalLoading &&
            searchLength > 0 &&
            userGroupList.length != 0
          "
          id="addNewSearchList"
          class="addNewSearchList"
        >
          <div [ngClass]="!!searchData?.length ? 'search-wrapper' : ''">
            <div (click)="addNewTask(searchValue, 'switch')" *ngIf="!searchData?.length" class="searched-task">
                    <em class="fas fa-plus plus-icon"></em>
                    <span
                      title="{{ searchValue }}"
                      class="task-title search-text task-timer"
                      >{{ searchValue }}</span
                    >
                  </div>
                  <div (click)="addNewTask(searchValue, 'switch')" *ngIf="(!!groupExist)" class="searched-task">
                    <em class="fas fa-plus plus-icon"></em>
                    <span
                      title="{{ searchValue }}"
                      class="task-title search-text task-timer"
                      >{{ searchValue }}</span
                    >
                  </div>
            <div class="search-task-list" *ngFor="let item of userGroupList">
              <div
                class="d-flex justify-content-between task-details searched-task"
                (click)="addNewTaskWithGroup(searchValue, item, 'switch')"
              >
                <div class="search-task-wrapper">
                  <em class="fas fa-plus plus-icon"></em>
                  <span
                    title="{{ searchValue }}"
                    class="task-title search-text task-timer"
                    >{{ searchValue }}</span
                  >
                </div>
                <span title="{{ item.name }}" class="search-task-group">{{
                  item.name
                }}</span>
              </div>
            </div>
          </div>
        </div>
        </div>

        <div class="tab-footer">
          <span class="billable-checkbox">
          <input
            class="checkbox"
            type="checkbox"
            formControlName="billableHour"
            [checked]="TimerForm.value.billableHour"
          />
          </span>
          <p class="billable-hour text-black">Billable Hours</p>
        </div>
      </div>
    </div>
    </div>
    <div
      class="dropdown-menu switch-dropdown dropdown-menu-left"
      id="switch-timer"
      aria-labelledby="dropdownMenuLink">
      <div class="switch-task-wrapper">
        <div
          class="d-flex align-items-center justify-content-between switch-time-header"
        >
          <h4 class="switch-task-title">Task:</h4>
          <div class="d-flex align-items-center">
            <img
              src="../../../../assets/img/switchtask-hourglass-solid.svg"
              class="switch-hourglass-icon"
              alt="hourglass"
            />
            <span class="switch-timer-text">{{ countDownTime }}</span>
          </div>
        </div>
        <div class="d-flex switch-task-body">
          <input
            name="test"
            type="text"
            class="input-field"
            formControlName="switchTimerForm"
            autocomplete="off"
            #search
            (input)="
              startTimerGetProject(TimerForm.value.switchTimerForm, 'switch')
            "
            placeholder="Please add the task you are switching too"
          />

          <button
            [disabled]="!isSwitchBtnLoading"
            [ngClass]="!isSwitchBtnLoading ? 'switch-task-btn' : ''"
            (click)="SwitchTaskTimer()"
            class="start-timer-btn"
          >
            Switch Task
          </button>
        </div>
        <div class="task-dropdown-wrapper">
        <div
          *ngIf="isSwitchmodalLoading && searchData?.length && searchLength > 0"
          class="searchList"
          id="searchList"
        >
          <div *ngFor="let item of searchData">
            <div
              class="d-flex justify-content-between task-details searched-task"
              (click)="changeSwitchTask(item)"
            >
              <span title="{{ item.title }}" class="task-title task-timer">{{
                item.title
              }}</span>
              <span
                title="{{ item.groupName }}"
                class="groups-name group-timer"
                >{{ item.groupName }}</span
              >
            </div>
          </div>
        </div>

        <div (click)="openmodel()">
          <div
            (click)="addNewTask(searchValue, 'switch')"
            [ngClass]="searchData?.length == 0 ? 'search-task-border' : ''"
            *ngIf="
              isSwitchmodalLoading &&
              userGroupList.length == 0 &&
              searchLength > 0
            "
            id="addNewSearchList"
            class="addNewSearchList task-details"
          >
            <div
              class="searched-task"
              [ngClass]="!!searchData?.length ? 'add-search-task' : ''"
            >
              <em class="fas fa-plus plus-icon"></em>
              <span class="task-title search-text task-timer">{{
                searchValue
              }}</span>
            </div>
          </div>
        </div>

        <div
          (click)="openmodel()"
          [ngClass]="searchData?.length == 0 ? 'search-taskgroup-border' : ''"
          *ngIf="
            isSwitchmodalLoading &&
            searchLength > 0 &&
            userGroupList.length != 0
          "
          id="addNewSearchList"
          class="addNewSearchList"
        >
          <div [ngClass]="!!searchData?.length ? 'search-wrapper' : ''">
            <div (click)="addNewTask(searchValue, 'switch')" *ngIf="!searchData?.length" class="searched-task">
                    <em class="fas fa-plus plus-icon"></em>
                    <span
                      title="{{ searchValue }}"
                      class="task-title search-text task-timer"
                      >{{ searchValue }}</span
                    >
                  </div>
                  <div (click)="addNewTask(searchValue, 'switch')" *ngIf="(!!groupExist)" class="searched-task">
                    <em class="fas fa-plus plus-icon"></em>
                    <span
                      title="{{ searchValue }}"
                      class="task-title search-text task-timer"
                      >{{ searchValue }}</span
                    >
                  </div>
            <div class="search-task-list" *ngFor="let item of userGroupList">
              <div
                class="d-flex justify-content-between task-details searched-task"
                (click)="addNewTaskWithGroup(searchValue, item, 'switch')"
              >
                <div class="search-task-wrapper">
                  <em class="fas fa-plus plus-icon"></em>
                  <span
                    title="{{ searchValue }}"
                    class="task-title search-text task-timer"
                    >{{ searchValue }}</span
                  >
                </div>
                <span title="{{ item.name }}" class="search-task-group">{{
                  item.name
                }}</span>
              </div>
            </div>
          </div>
        </div>
        </div>

        <div class="tab-footer">
          <span class="billable-checkbox">
          <input
            class="checkbox"
            type="checkbox"
            formControlName="billableHour"
            [checked]="TimerForm.value.billableHour"
          />
          </span>
          <p class="billable-hour text-black">Billable Hours</p>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-template #initiatingText>
  <div>
    <div class="text-right text-bold" style="color: black;">
      <button class="btn p-0" (click)="isPopOverOpen = !isPopOverOpen">
        <span aria-hidden="true" class="text-bold" style="color: black;">&times;</span>
      </button>
    </div>
    <div style="margin-top: -0.8rem;">
      <p style="color: #000;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;" class="mb-0">We've initiated a task for you. To try out Daybeam, feel free to either 
        close this task or switch to a different one and then close it out, using the buttons to the right.</p>
    </div>
  </div>
</ng-template>
